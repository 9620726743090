export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getMultiYearRanges({before= 0, after= 3}) {
  const currentYear = (new Date()).getFullYear();

  let yearRanges = [];
  for(let i = before; i < after; i++) {
    yearRanges.push((currentYear + i) + '-' + (currentYear + i + 1));
  }

  return yearRanges;
}
