import NewKPIForm from "./NewKPIForm";
import { ucfirst } from "../../helpers.js";

const KPITable = (props) => {
  const saveKPIHandler = (data) => {
    props.saveHandler(props.kraId, data);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-nowrap mb-0">
              <thead>
                <tr>
                  <th>KPI Description</th>
                  <th>Units</th>
                  <th>Weightage</th>
                  <th>Value</th>
                  <th>Tenure</th>
                  <th>--control--</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(props.kpis).map((kpiId) => {
                  const item = props.kpis[kpiId];

                  return <>
                    <tr key={item.kpi_id}>
                      <td>{item.description}</td>
                      <td>{item.units}</td>
                      <td>{item.weightage}</td>
                      <td>{item.value}</td>
                      <td>{ucfirst(item.tenure)}</td>
                      <td>--control--</td>
                    </tr>
                  </>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <NewKPIForm saveHandler={saveKPIHandler}/>
    </>
  );
};

export default KPITable;
