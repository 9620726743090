import { configureStore } from "@reduxjs/toolkit";
import { bottomUpConfigSlice } from "./store/config_bottom_up";
import { externalParticipantsConfigSlice } from "./store/config_external_participants";
import { peerToPeerConfigSlice } from "./store/config_peer_to_peer";
import { topDownConfigSlice } from "./store/config_top_down";
import { krasSlice } from "./store/kras";
import { userSlice } from "./store/user"; // Make sure this import is correct

const reducer = {
  user: userSlice.reducer, // Access the 'reducer' property of the userSlice
  kras: krasSlice.reducer,
  topDownConfig: topDownConfigSlice.reducer,
  peerToPeerConfig: peerToPeerConfigSlice.reducer,
  externalParticipantsConfig: externalParticipantsConfigSlice.reducer,
  bottomUpConfig: bottomUpConfigSlice.reducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
