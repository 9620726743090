import ContentContainer from "../../layout/ContentContainer";
import { Outlet } from "react-router-dom";

const Config = () => {
  return (
    <ContentContainer>
      <div className="row h-25">
        <h3 className="page-title">Configuration</h3>
      </div>

      <Outlet />
    </ContentContainer>
  );
};

export default Config;
