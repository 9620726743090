import { redirect } from "react-router-dom";

export function restoreLogin () {

  const details = JSON.parse(localStorage.getItem("loginDetails"));

  if(details?.access_token) {
    return {value: {name: details.first_name + ' ' + details.last_name, ...details}};
  }

  return { value: { name: "", email: "" } };
}


export function isLoggedIn () {

  const details = JSON.parse(localStorage.getItem("loginDetails"));

  if(!details?.access_token) {
    return redirect("/login");
  }
}


