import Select from "react-select";

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#ff9b44"
      : state.isDisabled
        ? "#eee"
        : "#fff",
    color: state.isFocused ? "#fff" : state.isDisabled ? "#ccc" : "#000",
    "&:hover": {
      backgroundColor: "#ff9b44",
    },
    cursor: state.isDisabled ? "not-allowed" : state.cursor,
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const FormSelect = (props) => {
  return (
    <>
      <Select {...props} styles={selectStyles} />
    </>
  );
};

export default FormSelect;
