import { createSlice } from "@reduxjs/toolkit";
import { restoreLogin } from "../session/check_login";

const initial = restoreLogin();

export const userSlice = createSlice({
  name: "user",
  initialState: initial,
  reducers: {
    login: (state, { payload }) => {
      payload.name = payload.first_name + ' ' + payload.last_name;
      state.value = payload;
    },
    logout: (state) => {
      state.value = { name: "", email: "" };
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
  },
});

export const { login, logout, setLayout } = userSlice.actions;
export default userSlice.reducer;
