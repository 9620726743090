import axios from 'axios'
import { api_base_url } from './base_urls';

var axiosInstance = axios.create({
  baseURL: api_base_url,
  timeout: 100000
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 error, e.g., redirect to login or refresh token
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
