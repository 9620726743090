import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const SevenScreen = () => {
    const [showModal, setShowModal] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [formData, setFormData] = useState({
        programName: '',
        programTopic: '',
        trainerName: '',
        dateTime: '',
        location: '',
        status: '',
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPrograms([...programs, formData]);
        setFormData({
            programName: '',
            programTopic: '',
            trainerName: '',
            dateTime: '',
            location: '',
            status: '',
        });
        handleClose();
    };


    return (
        <div style={{ color: "black" }}>

            <div className="card">
                <div className="card-body">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-md-4">
                                <label>Do you have an Employee Development Program</label>
                            </div>
                            <div className="col-md-1">
                                <Form.Group controlId="formMandatory">
                                    <Form.Check
                                        type="switch"
                                        id="mandatory-switch"
                                        label=""
                                        name="mandatory"
                                    />
                                </Form.Group>

                                </div>

                        </div>

                        <div className='row' style={{ marginTop: 10 }}>
                            <div className="col-md-1">
                                <label>Template</label>
                            </div>
                            <div className="col-md-3">
                                <Form.Group controlId="ratingType">
                                    <Form.Control as="select">
                                        <option value="Numeric">Template 1</option>
                                        <option value="Percentage">Template 2</option>
                                        <option value="Grade">Template 3</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                        </div>

                        </div>

                    <div className="container-fluid">
                        <h4>ClassRoom</h4>
                            <div className="row">
                                <div className="col-md-1">
                                    <label>Part</label>

                                   
                            </div>
                            <div className="col-md-1">
                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                            </div>
                            <div className="col-md-2">
                                <Form.Group controlId="ratingType">
                                    <Form.Control as="select">
                                        <option value="Numeric">1</option>
                                        <option value="Percentage">2</option>
                                        <option value="Grade">3</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                                
                           </div>
                        


                        <div className="row">            
                            <div className="col-md-4">
                                <Form.Group controlId="ratingType">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control as="select">
                                        <option value="Numeric">Numeric</option>
                                        <option value="Percentage">Percentage</option>
                                        <option value="Grade">Grade</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-1">
                                <Form.Label>.</Form.Label>

                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                                </div>

                        </div>
                        
                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-1">

                            <Form.Group controlId="formMandatory">
                                <Form.Label>Mandatory</Form.Label>
                                
                                </Form.Group>
                            </div>
                            <div className="col-md-1">

                                <Form.Group controlId="formMandatory">
                                    <Form.Check
                                        type="switch"
                                        id="mandatory-switch"
                                        label=""
                                        name="mandatory"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-2">
                                <Button variant="dark" onClick={handleShow}>
                                    + Add
                                </Button>

                                </div>

                        </div>
                        

                                    
                        <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-12"></div>
                                    <h5>Program</h5>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr.no</th>
                                                <th>Program Name</th>
                                                <th>Program Topic</th>
                                                <th>Trainer Name</th>
                                                <th>Program Date & Time</th>
                                                <th>Location</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {programs.map((program, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{program.programName}</td>
                                                    <td>{program.programTopic}</td>
                                                    <td>{program.trainerName}</td>
                                                    <td>{program.dateTime}</td>
                                                    <td>{program.location}</td>
                                                    <td>{program.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                  

                                   
                        </div>
                        
                    </div>
                        </div>

                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Program</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formProgramName">
                                        <Form.Label>Program Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="programName"
                                            value={formData.programName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formProgramTopic">
                                        <Form.Label>Program Topic</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="programTopic"
                                            value={formData.programTopic}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formTrainerName">
                                        <Form.Label>Trainer Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="trainerName"
                                            value={formData.trainerName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formDateTime">
                                        <Form.Label>Program Date & Time</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name="dateTime"
                                            value={formData.dateTime}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formLocation">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formStatus">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                            <Button variant="primary" type="submit" style={{ marginTop: 10 }}>
                                        Add Program
                                    </Button>
                                </Form>
                            </Modal.Body>
                        </Modal>
           

            
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-success">Save</button>
                        </div>
                    </div>
                </div>
                

    );
};

export default SevenScreen;