import React, { useEffect, useState } from "react";
import KPITable from "../../../components/Goals/KPITable";
import NewKRAForm from "../../../components/Goals/NewKRAForm";
import Profile from "../../../components/User/Profile";
import { useDispatch, useSelector } from "react-redux";
import { add, edit, save } from "../../../store/kras";

const kraCategoryMaster = [
  { label: "People", value: "People" },
  { label: "Business", value: "Business" },
  { label: "Customer", value: "Customer" },
  { label: "Process", value: "Process" },
];

const GoalsCreation1 = () => {
  const [kpiToggles, setKPIToggles] = useState({});
  const employeeKRAs = useSelector((state) => {
    console.log(state.kras);
    return state.kras.value;
  });

  const dispatch = useDispatch();

  const [kraFormSettings, setKRAFormSettings] = useState({
    show: false,
    handler: null,
    data: null,
  });

  const yearSpan = "2024-2025";

  // const employeeDetails = {
  //   user_id: 1,
  //   name: "Amelia Rose",
  //   code: "EMP-12345",
  //   department: "Marketing",
  //   manager: "Christopher Wright",
  //   dateOfJoin: "03/04/2022",
  // };

  const [kraCategories, setKraCategories] = useState([...kraCategoryMaster]);

  useEffect(() => {
    const initial = {
      1: {
        category: "People",
        goalType: "Individual",
        weightage: 30,
        description:
          "Complete leadership training program and mentor two junior team members",
        kpis: {
          1: {
            description: "Appoint 2 trainers",
            units: "Units",
            weightage: "80",
            value: "2",
            tenure: "monthly",
          },
        },
      },
      2: {
        category: "Business",
        goalType: "Functional",
        weightage: 40,
        description:
          "Increase department revenue by 12% through new product initiatives",
        kpis: {},
      },
      3: {
        category: "Customer",
        goalType: "Functional",
        weightage: 40,
        description:
          "Increase department revenue by 12% through new product initiatives",
        kpis: {},
      },
      4: {
        category: "Process",
        goalType: "Functional",
        weightage: 40,
        description:
          "Increase department revenue by 12% through new product initiatives",
        kpis: {},
      },
    };

    dispatch(save({ kras: initial }));

    const categories = new Set(
      Object.keys(initial).map((kra) => initial[kra].category),
    );
    const updatedCategories = kraCategoryMaster.filter((cat) => {
      if (categories.has(cat.label)) {
        return false;
      } else {
        return true;
      }
    });

    setKraCategories(updatedCategories);
  }, []);

  const currentYear = new Date().getFullYear(); // Get the current year

  const years = [];
  for (let i = currentYear - 2; i < currentYear + 3; i++) {
    years.push({ label: `${i}-${i + 1}`, value: `${i}-${i + 1}` });
  }

  const handlerToggleKPIs = (id) => {
    setKPIToggles((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const saveKRAHandler = (newKRA) => {
    // TODO: send the KRA to the server and get a new kra_id
    // Logic below has to be removed once the backend kicks in
    const highestKraId = Object.keys(employeeKRAs).reduce(
      (max, kraId) => Math.max(max, kraId),
      0,
    );

    dispatch(add({ newKRA: { kra_id: highestKraId + 2, ...newKRA } }));
  };

  const saveKPIHandler = (kraId, newKPI) => {
    const existingKRA = { ...employeeKRAs[kraId] };
    const newKPIId = Math.max(...Object.keys(existingKRA.kpis)) + 1;
    existingKRA.kpis[newKPIId] = newKPI;

    dispatch(edit({ kra_id: kraId, ...existingKRA }));
  };

  return (
    <>
      {kraFormSettings.show && (
        <NewKRAForm
          saveHandler={saveKRAHandler}
          categories={kraCategories}
          show={kraFormSettings.show}
          hideHandler={() => setKRAFormSettings(false)}
        />
      )}
      {employeeKRAs && (
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row h-25">
              <h3 className="page-title">Key Responsibity Areas</h3>
            </div>

            <div className="user-name mt-2 mb-3">Review Year: {yearSpan}</div>

            <Profile />

            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 d-flex flex-row-reverse">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mt-1 me-1 "
                      onClick={() =>
                        setKRAFormSettings((s) => ({ ...s, show: true }))
                      }
                    >
                      + Add KRA
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Key Result Area</th>
                          <th>Goal Type</th>
                          <th>Weightage</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(employeeKRAs).map((kraId) => {
                          const item = employeeKRAs[kraId];
                          return (
                            <>
                              <tr
                                key={kraId}
                                className={
                                  kpiToggles[kraId] ? "table-primary" : ""
                                }
                              >
                                <td>
                                  <button
                                    className="btn button-outline-none"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlerToggleKPIs(kraId);
                                    }}
                                  >
                                    {kpiToggles[kraId] ? (
                                      <i
                                        className="fa fa-angle-down fw-bold"
                                        data-bs-toggle="tooltip"
                                        title="fa fa-angle-down"
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-right"
                                        data-bs-toggle="tooltip"
                                        title="fa fa-angle-right"
                                      ></i>
                                    )}
                                  </button>
                                </td>
                                <td>{item.category}</td>
                                <td>{item.goalType}</td>
                                <td>{item.weightage}</td>
                                <td>{item.description}</td>
                                <td>
                                  <button className="btn btn-sm btn-primary">
                                    Edit
                                  </button>
                                </td>
                              </tr>
                              {kpiToggles[kraId] && (
                                <tr className="table-secondary">
                                  <td></td>
                                  <td colSpan="5">
                                    <KPITable
                                      kpis={item.kpis}
                                      kraId={kraId}
                                      saveHandler={saveKPIHandler}
                                    />
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoalsCreation1;
