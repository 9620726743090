import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";
import FormSelect from "../../../components/Inputs/FormSelect";

const goalStatuses = [
  {
    employee: "Ragini Shinde",
    goals: [
      {
        name: "Sell 100 cars in the quarter",
        status: "approved",
      },
      {
        name: "Improve customer satisfaction score by 15%",
        status: "pending",
      },
      {
        name: "Develop and implement a new sales strategy",
        status: "rejected",
      },
      {
        name: "Attend advanced negotiation skills workshop",
        status: "saved",
      },
    ],
  },
  {
    employee: "Raj Mehra",
    goals: [
      {
        name: "Increase sales by 20% in Q3",
        status: "pending",
      },
      {
        name: "Implement new CRM system",
        status: "approved",
      },
      {
        name: "Mentor two junior sales representatives",
        status: "saved",
      },
      {
        name: "Achieve 98% customer satisfaction rate",
        status: "rejected",
      },
      {
        name: "Expand product knowledge through online courses",
        status: "approved",
      },
    ],
  },
  {
    employee: "Vridha Rijul",
    goals: [
      {
        name: "Launch marketing campaign for new product line",
        status: "pending",
      },
      {
        name: "Reduce operational costs by 10%",
        status: "approved",
      },
      {
        name: "Organize team-building event for department",
        status: "saved",
      },
    ],
  },
  {
    employee: "Sai Shirke",
    goals: [
      {
        name: "Hire and train 5 new sales representatives",
        status: "approved",
      },
      {
        name: "Achieve 95% customer retention rate",
        status: "approved",
      },
      {
        name: "Develop a comprehensive sales forecast for next year",
        status: "approved",
      },
      {
        name: "Improve cold calling conversion rate by 5%",
        status: "approved",
      },
    ],
  },
];
const GoalsDashboard = (props) => {
  // Can have the following filters: all approved, rejected, pending, saved
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [employees, setEmployees] = useState([]);

  const updateStatus = (status) => {
    setSelectedStatus(status.value);
  };

  useEffect(() => {
    const filtered = goalStatuses.filter((empl) => {
      for (let i = 0; i < empl.goals.length; i++) {
        if (empl.goals[i].status === selectedStatus) {
          return true;
        }
      }
      return false;
    });

    setEmployees(
      filtered.reduce((emps, goal) => {
        emps.push(goal.employee);

        return emps;
      }, []),
    );
  }, [selectedStatus]);

  const statusCounts = goalStatuses.reduce((acc, employee) => {
    employee.goals.forEach((goal) => {
      acc[goal.status] = (acc[goal.status] || 0) + 1;
    });

    return acc;
  }, {});

  const statuses = ["Approved", "Saved", "Rejected", "Pending"];
  const pie_series = ["approved", "saved", "rejected", "pending"].map((v) => {
    return statusCounts[v];
  });

  const selectoptions = [
    { label: "Status - Pending", value: "pending" },
    { label: "Status - Approved", value: "approved" },
    { label: "Status - Saved", value: "saved" },
    { label: "Status - Rejected", value: "rejected" },
  ];

  const donutChart = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    // colors: ["#4CAF50", "#2196F3", "#F44336", "#FFC107"],
    labels: statuses,
    series: pie_series,
    dataLabels: {
      formatter: function (_, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <h3 className="page-title">Goals Overview</h3>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-6 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Status</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <ReactApexChart
                      options={donutChart}
                      series={donutChart.series}
                      type="donut"
                      height={350}
                    />
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Employees</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <div>
                      <FormSelect
                        defaultValue={{
                          value: "pending",
                          label: "Status - Pending",
                        }}
                        options={selectoptions}
                        placeholder="-- Select --"
                        onChange={updateStatus}
                      />
                    </div>

                    <div>
                      {employees.map((employee) => (
                        <div
                          key={employee}
                          style={{
                            margin: "10px 0",
                            padding: "10px",
                            backgroundColor: "#f0f0f0",
                            overflow: "scroll",
                          }}
                        >
                          {employee}
                        </div>
                      ))}
                    </div>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsDashboard;
