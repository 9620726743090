import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  setQuestionsCount,
  togglePeerSetting,
  setRatingRange,
  addQuestion,
  removeQuestion,
  toggleApplicabilityProperty,
  updateQuestion,
  addNewApplicability,
} from "../../../store/config_external_participants";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Question from "../../../components/Configuration/Question";
import { Button } from "react-bootstrap";

const topDownFormSchema = Yup.object().shape({
  category: Yup.string()
    .transform((v) => (v ? v.value : null))
    .required("Category is required"),
  goalType: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(["Individual", "Functional", "Common"], "Invalid Goal type")
    .required("Goal Type is required"),
  weightage: Yup.mixed()
    .test("is-number", "Invalid (1 <= Weightage <= 100)", function (value) {
      let num;
      if (typeof value === "object" && value !== null) {
        num = value.value;
      } else {
        num = value;
      }

      return Yup.number().isValidSync(num) && num > 0 && num <= 100;
    })
    .transform(function (value) {
      if (typeof value === "object" && value !== null) {
        return value.value;
      }
      return value;
    })
    .required("Weightage is required"),
  description: Yup.string().required("Description is required"),
});

const FiveScreen = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [editFormDetails, setEditFormDetails] = useState(null);
  const [newApplicability, setNewApplicability] = useState(null);
  const externalParticipantsConfig = useSelector(
    (state) => state.externalParticipantsConfig,
  );
  const dispatch = useDispatch();

  const { handleSubmit } = useForm({
    resolver: yupResolver(topDownFormSchema),
  });

  const questionSaveHandler = (question) => {
    if (question.idx !== undefined) {
      dispatch(updateQuestion({ idx: question.idx, question: question }));
    } else {
      dispatch(addQuestion({ question: question }));
    }
    setShowForm(false);
  };

  const editQuestionHandler = (idx) => {
    setEditFormDetails({
      ...externalParticipantsConfig.questions[idx],
      idx: idx,
    });
  };

  useEffect(() => {
    if (editFormDetails !== null) {
      setShowForm(true);
    }
  }, [editFormDetails]);

  const saveHandler = (data) => {
    props.saveHandler(data);
  };

  const closeQuestionModal = () => {
    setEditFormDetails(null);
    setShowForm(false);
  };

  const nextHandler = () => {
    console.log(externalParticipantsConfig);
  };

  return (
    <div className="card">
      <div className="content container-fluid">
        <div className="card-body">
          <h3 className="border-bottom border-2 my-2 mb-5">
            External Participants
          </h3>
          <form onSubmit={handleSubmit(saveHandler)}>
            <section id="peer-peer-main">
              <div className="row pb-2">
                <div className="col-md-2">
                  <span className="fw-bold">
                    Number of Question's to include in form
                  </span>
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="number"
                    value={externalParticipantsConfig.questions_count}
                    onChange={(e) => {
                      dispatch(setQuestionsCount(e.target.value));
                    }}
                    min="1"
                    max="10"
                  />
                </div>

                <div className="col-md-2">
                  <span className="fw-bold">Auto Populate Question </span>
                </div>
                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={() =>
                        dispatch(
                          togglePeerSetting({ setting: "auto_populate" }),
                        )
                      }
                      checked={externalParticipantsConfig.auto_populate}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="col-md-4 text-end pe-5">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowForm(true)}
                  >
                    Create Form
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2">
                  <span className="fw-bold">Grading</span>
                </div>

                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={() =>
                        dispatch(togglePeerSetting({ setting: "is_grading" }))
                      }
                      checked={externalParticipantsConfig.is_grading}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2">
                  <span className="fw-bold">Rating</span>
                </div>
                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={() =>
                        dispatch(togglePeerSetting({ setting: "is_rating" }))
                      }
                      checked={externalParticipantsConfig.is_rating}
                      type="checkbox"
                    />
                  </div>
                </div>

                <div className="col-md-2">Range From</div>
                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      type="number"
                      value={externalParticipantsConfig.rating_range_from}
                      onChange={(e) => {
                        dispatch(
                          setRatingRange({
                            property: "from",
                            value: e.target.value,
                          }),
                        );
                      }}
                      min="1"
                      max="1"
                    />
                  </div>
                </div>
                <div className="col-md-2">Range To</div>
                <div className="col-md-2">
                  <input
                    type="number"
                    value={externalParticipantsConfig.rating_range_to}
                    onChange={(e) => {
                      dispatch(
                        setRatingRange({
                          property: "to",
                          value: e.target.value,
                        }),
                      );
                    }}
                    min="1"
                    max="10"
                  />
                </div>
              </div>
            </section>

            <section className="mt-5" id="peer-rating-def">
              <div className="row mb-2">
                <div className="col">
                  <span className="fw-bold text-decoration-underline">
                    Questions
                  </span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th scope="col">Question</th>
                        <th scope="col">Answer Type</th>
                        <th scope="col">Options</th>
                        <th scope="col">Controls</th>
                      </tr>
                    </thead>
                    <tbody>
                      {externalParticipantsConfig.questions.map(
                        (question, idx) => (
                          <tr key={idx}>
                            <td>{question.question}</td>
                            <td>{question.answer_type}</td>
                            <td>
                              <span className="fst-italic">
                                {question.levels
                                  ? question.levels.join(", ")
                                  : "&nbsp;"}
                              </span>
                            </td>
                            <td>
                              <Button
                                className="btn btn-primary"
                                onClick={() => editQuestionHandler(idx)}
                              >
                                Edit
                              </Button>

                              <Button
                                className="btn btn-danger ms-2"
                                onClick={() => dispatch(removeQuestion(idx))}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-2 fw-bold">Include in Final Rating</div>
                <div className="col-md-1">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={() =>
                        dispatch(
                          togglePeerSetting({
                            setting: "include_in_final_rating",
                          }),
                        )
                      }
                      checked={
                        externalParticipantsConfig.include_in_final_rating
                      }
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="col-md-7"></div>
              </div>
            </section>

            <section className="mt-3" id="peer-applicability">
              <div className="row">
                <div className="col-md-2 fw-bold">Review applicable for: </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <ul className="list-group">
                    {Object.keys(externalParticipantsConfig.applicability).map(
                      (vertical, idx) => {
                        return (
                          <li className="list-group-item" key={idx}>
                            <div className="row">
                              <div className="col">{vertical}: </div>
                              <div className="col">
                                <input
                                  className="form-check-input"
                                  onChange={() =>
                                    dispatch(
                                      toggleApplicabilityProperty({
                                        property: vertical,
                                      }),
                                    )
                                  }
                                  checked={
                                    externalParticipantsConfig["applicability"][
                                      vertical
                                    ]
                                  }
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </li>
                        );
                      },
                    )}
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            name="newApplicability"
                            value={newApplicability}
                            onChange={(e) =>
                              setNewApplicability(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <Button
                            onClick={() => {
                              dispatch(addNewApplicability(newApplicability));
                              setNewApplicability('');
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-3 fw-bold">
                  Needs to be included in the final review
                </div>
                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={() =>
                        dispatch(
                          togglePeerSetting({
                            setting: "include_in_final_review",
                          }),
                        )
                      }
                      checked={
                        externalParticipantsConfig.include_in_final_review
                      }
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="next">
              <div className="row">
                <div className="col-md-11"></div>
                <div className="col-md-1">
                  <Button className="btn btn-primary" onClick={nextHandler}>
                    Save
                  </Button>
                </div>
              </div>
            </section>
          </form>
          {showForm && (
            <Question
              show={true}
              ratingRange={5}
              onHide={closeQuestionModal}
              closeModal={closeQuestionModal}
              saveHandler={questionSaveHandler}
              initial={editFormDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FiveScreen;
