import React, { useState } from "react";
import FormSelect from "../Inputs/FormSelect";
import { Controller, useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

const kraValidationSchema = Yup.object().shape({
  category: Yup.string()
    .transform((v) => (v ? v.value : null))
    .required("Category is required"),
  goalType: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(["Individual", "Functional", "Common"], "Invalid Goal type")
    .required("Goal Type is required"),
  weightage: Yup.mixed()
    .test("is-number", "Invalid (1 <= Weightage <= 100)", function (value) {
      let num;
      if (typeof value === "object" && value !== null) {
        num = value.value;
      } else {
        num = value;
      }

      return Yup.number().isValidSync(num) && num > 0 && num <= 100;
    })
    .transform(function (value) {
      if (typeof value === "object" && value !== null) {
        return value.value;
      }
      return value;
    })
    .required("Weightage is required"),
  description: Yup.string().required("Description is required"),
});

const NewKRAForm = (props) => {
  const [isCustomWeightage, setIsCustomWeightage] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(kraValidationSchema),
  });

  const goalTypes = [
    {
      label: "Individual",
      value: "Individual",
    },
    {
      label: "Functional",
      value: "Functional",
    },
    {
      label: "Common",
      value: "Common",
    },
  ];

  const weightages = [
    {
      label: "10%",
      value: 10,
    },
    {
      label: "20%",
      value: 20,
    },
    {
      label: "30%",
      value: 30,
    },
    {
      label: "40%",
      value: 40,
    },
    {
      label: "Custom",
      value: "Custom",
    },
  ];

  const closeModal = () => {
    props.hideHandler();
  };

  const resetForm = () => {
    const fields = ["category", "weightage", "goalType", "description"];
    fields.forEach((fld) => setValue(fld, null));

    closeModal();
  };

  const saveHandler = (data) => {
    resetForm();

    props.saveHandler(data);
  };

  return (
    <>
      <Modal show={props.show} onHide={closeModal}>
        <Modal.Header>
          <h4 className="modal-title">Add New KRA</h4>

          <button
            type="button"
            className="btn-close float-right"
            aria-label="Close"
            onClick={closeModal}
          >
            <i className="fas fa-close"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit(saveHandler)}>
            <div className="row">
              <div className="col mb-3">
                <label className="col-form-label">Category</label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      isClearable
                      {...field}
                      options={props.categories}
                    />
                  )}
                />
                <span className="text-danger">
                  {" "}
                  {errors.category?.message}{" "}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label className="col-form-label">Goal Type</label>
                <Controller
                  name="goalType"
                  control={control}
                  render={({ field }) => (
                    <FormSelect {...field} isClearable options={goalTypes} />
                  )}
                />
                <span className="text-danger">
                  {" "}
                  {errors.goalType?.message}{" "}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label className="col-form-label">Weightage</label>
                {isCustomWeightage ? (
                  <input
                    className="form-control"
                    {...register("weightage")}
                    placeholder="Custom weightage"
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        setIsCustomWeightage(false);
                      }
                    }}
                  />
                ) : (
                  <Controller
                    name="weightage"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        isClearable
                        options={weightages}
                        onChange={(v) => {
                          field.onChange(v);
                          if (v && v.value === "Custom") {
                            setIsCustomWeightage(true);
                            field.onChange(null);
                          }
                        }}
                      />
                    )}
                  />
                )}
                <span className="text-danger">
                  {" "}
                  {errors.weightage?.message}{" "}
                </span>
                <span className="text-danger">
                  {" "}
                  {errors.customWeightage?.message}{" "}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label className="col-form-label">Description</label>
                <input
                  type="text"
                  {...register("description")}
                  className="form-control"
                  placeholder="KRA Description"
                />
                <span className="text-danger">
                  {" "}
                  {errors.description?.message}{" "}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label className="col-form-label">&nbsp;</label>
                <br />
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
                <input
                  className="btn btn-primary ms-3"
                  type="submit"
                  value="+ Add KRA"
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewKRAForm;
