import FormSelect from "../Inputs/FormSelect";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

const kpiValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  units: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(
      ["Units", "Days", "Date", "Weight", "Ratio", "ValueInr"],
      "Invalid Goal type",
    )
    .required("Please select units"),
  value: Yup.number()
  .typeError("Invalid numeric value")
    .required("Enter KPI value"),
  weightage: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(
      ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
      "Invalid weightage",
    )
    .required("Please select units"),
  tenure: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(
      ["monthly", "quarterly", "yearly"],
      "Invalid tenure",
    )
    .required("Please select the tenure"),

});

const NewKPIForm = (props) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(kpiValidationSchema),
  });

  const units = [
    { label: "Units", value: "Units" },
    { label: "Days", value: "Days" },
    { label: "Date", value: "Date" },
    { label: "Weight", value: "Weight" },
    { label: "Ratio", value: "Ratio" },
    { label: "ValueInr", value: "ValueInr" },
  ];

  const weightages = [
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
    { label: "30%", value: "30" },
    { label: "40%", value: "40" },
    { label: "50%", value: "50" },
    { label: "60%", value: "60" },
    { label: "70%", value: "70" },
    { label: "80%", value: "80" },
    { label: "90%", value: "90" },
    { label: "100%", value: "100" },
  ];

  const tenures = [
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ]
  const resetForm = () => {
    const fields = ['units', 'weightage', 'value', 'description'];

    fields.forEach(fld => setValue(fld, null))
  }

  const saveHandler = (data) => {
    props.saveHandler(data);

    resetForm();

  };

  return (
    <form onSubmit={handleSubmit(saveHandler)}>
      <div className="container">
        <div className="row mt-5 mb-3">
          <div className="col-md-3">
            <label className="col-form-label">KPI Description</label>
            <input
              type="text"
              {...register("description")}
              className="form-control"
              placeholder="KPI Description"
            />
            <span className="text-danger"> {errors.description?.message} </span>
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Units</label>
            <Controller
              name="units"
              control={control}
              render={({ field }) => (
                <FormSelect options={units} isClearable {...field} />
              )}
            />
            <span className="text-danger"> {errors.units?.message} </span>
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Weightage</label>
            <Controller
              name="weightage"
              control={control}
              render={({ field }) => (
                <FormSelect isClearable {...field} options={weightages} />
              )}
            />
            <span className="text-danger"> {errors.weightage?.message} </span>
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Value</label>
            <input
              type="text"
              {...register("value")}
              className="form-control"
              placeholder="Value"
            />
            <span className="text-danger"> {errors.value?.message} </span>
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Tenure</label>
            <Controller
              name="tenure"
              control={control}
              render={({ field }) => (
                <FormSelect isClearable {...field} options={tenures} />
              )}
            />
            <span className="text-danger"> {errors.tenure?.message} </span>
          </div>
          <div className="col-md-1">
            <label className="col-form-label">&nbsp;</label>
            <br />
            <button
              className="btn btn-primary h-50"
              type="button"
              onClick={handleSubmit(saveHandler)}
            >
              + Add KPI
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewKPIForm;
