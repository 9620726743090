import { createSlice } from "@reduxjs/toolkit";

const initial = {
  questions_count: 5,
  auto_populate: false,
  is_grading: false,
  is_rating: false,
  rating_range_from: 1,
  rating_range_to: 10,
  questions: [],
  include_in_final_rating: false,
  applicability: {
    Customer: false,
    Vendors: false,
  },
  include_in_final_review: false,
};

export const externalParticipantsConfigSlice = createSlice({
  name: "top-down-config",
  initialState: initial,
  reducers: {
    setQuestionsCount: (state, { payload }) => {
      state.questions_count = payload
    },
    togglePeerSetting: (state, { payload: { setting } }) => {
      state[setting] = !state[setting];
    },
    setRatingRange: (state, { payload: { property, value } }) => {
      if( property === 'from') {
        state.rating_range_from = value;
      } else {
        state.rating_range_to = value;
      }
    },
    addQuestion: (state, { payload: { question }}) => {
      state.questions.push(question);
    },
    updateQuestion: (state, { payload: { question, idx }}) => {
      state.questions[idx] = question;
    },
    removeQuestion: (state, { payload: {index}}) => {
      state.questions.splice(index, 1);
    },
    toggleApplicabilityProperty: (state, { payload: { property } }) => {
      state.applicability[property] = !state.applicability[property];
    },
    addNewApplicability: (state, { payload }) => {
      if(payload && payload.length > 0) {
        state.applicability[payload] = true;
      }
    }
  },
});

export const {
  setQuestionsCount,
  togglePeerSetting,
  setRatingRange,
  addQuestion,
  removeQuestion,
  toggleApplicabilityProperty,
  updateQuestion,
  addNewApplicability,
} = externalParticipantsConfigSlice.actions;

export default externalParticipantsConfigSlice.reducer;
