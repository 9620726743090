import { createSlice } from "@reduxjs/toolkit";

function updateReviewLevels(newCount, settings) {
  const newSettings = {};

  for (let $i = 1; $i <= newCount; $i++) {
    if (!($i in settings)) {
      newSettings[$i] = {
        periodic: { comment: false, grading: false },
        interim: { comment: false, grading: false },
        target: { comment: false, grading: false },
      };
    } else {
      newSettings[$i] = settings[$i];
    }
  }

  return newSettings;
}

const initial = {
  main: {
    goalsetting: {
      label: "Goalsetting",
      enabled: null,
      alias: null,
      comment: null,
      grading: null,
    },
    periodic: {
      label: "Periodic",
      enabled: true,
      alias: null,
      comment: false,
      grading: false,
    },
    interim: {
      label: "Interim",
      enabled: true,
      alias: null,
      comment: false,
      grading: false,
    },
    target: {
      label: "Target",
      enabled: true,
      alias: null,
      comment: false,
      grading: false,
    },
  },
  target: {
    annual: false,
    multiyear: null,
  },
  interim: {
    monthly: false,
    quarterly: true,
    half_yearly: true,
  },
  periodic: {
    daily: false,
    weekly: true,
    monthly: false,
    fortnightly: true,
  },
  dates: {
    goalsetting: {
      set: null,
      approve: null,
    },
    periodic: {
      set: null,
      approve: null,
    },
    interim: {
      set: null,
      approve: null,
    },
    target: {
      set: null,
      approve: null,
    },
  },
  review_hierarchy: {
    periodic: 1,
    interim: 1,
    target: 1,
  },
  review_level_setting: {
    1: {
      periodic: { comment: false, grading: false },
      interim: { comment: false, grading: false },
      target: { comment: false, grading: false },
    },
  },
};

export const topDownConfigSlice = createSlice({
  name: "top-down-config",
  initialState: initial,
  reducers: {
    toggleSetting: (state, { payload: { setting } }) => {
      state.main[setting].enabled = !state.main[setting].enabled;
    },
    setAlias: (state, { payload: { setting, alias } }) => {
      state.main[setting].alias = alias;
    },
    toggleSettingProperty: (state, { payload: { setting, property } }) => {
      state.main[setting][property] = !state.main[setting][property];
    },
    toggleTargetAnnual: (state) => {
      state.target.annual = !state.target.annual;
    },
    setTargetMultiYear: (state, { payload }) => {
      state.target.multiyear = payload;
    },
    toggleInterimProperty: (state, { payload: { property } }) => {
      state.interim[property] = !state.interim[property];
    },
    togglePeriodicProperty: (state, { payload: { property } }) => {
      state.periodic[property] = !state.periodic[property];
    },
    setSettingDate: (state, { payload: { setting, property, value } }) => {
      state.dates[setting][property] = value;
    },
    setReviewLevel: (state, { payload: { property, value } }) => {
      state.review_hierarchy[property] = value;

      const reviewLevelCount = Math.max(
        ...Object.keys(state.review_hierarchy).map(
          (p) => state.review_hierarchy[p],
        ),
      );

      if (reviewLevelCount != Object.keys(state.review_hierarchy[property])) {
        const reviewLevels = updateReviewLevels(
          reviewLevelCount,
          state.review_level_setting,
        );

        state.review_level_setting = reviewLevels;
      }
    },
    toggleReviewLevel: (state, { payload: { level, type, property } }) => {
      state.review_level_setting[level][type][property] =
        !state.review_level_setting[level][type][property];
    },
  },
});

export const {
  toggleSetting,
  toggleSettingProperty,
  toggleInterimProperty,
  togglePeriodicProperty,
  toggleTargetAnnual,
  toggleReviewLevel,
  setTargetMultiYear,
  setSettingDate,
  setAlias,
  setReviewLevel,
} = topDownConfigSlice.actions;

export default topDownConfigSlice.reducer;
