import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Modal, Button, Form, Table, Col} from 'react-bootstrap';
import FormSelect from "../../../components/Inputs/FormSelect";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { api_base_url } from "../../../base_urls";
import axios from "../../../axios";



const KeyResultAreasValidationSchema = () =>
    Yup.object().shape({
        no_of_kra: Yup.string()
            .transform((v) => v ? v.value : null)
            .required("Please Select No Of KRA"),
    });
    

function KeyResultAreas() {
    // State variables
    const [kraChecked, setKraChecked] = useState(false);
    const [totalKraWeightage, setTotalKraWeightage] = useState(0);
    const [minKra, setMinKra] = useState(0);
    const [show, setShow] = useState(false);
    const [showKraForm, setShowKraForm] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        industry: '',
        category: '',
        unit: '',
        department: '',
        weightage: '',
    });
    const [KraData, setKraData] = useState({
        category: '',
        weightage: '',
    });
    const [tableData, setTableData] = useState([]);
    const [tableDataForKra, setTableDataForKra] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseForKra = () => setShowKraForm(false);
    const handleShowKra = () => setShowKraForm(true);
    const [ratingType, setRatingType] = useState('Numeric');
    const [rangeFrom, setRangeFrom] = useState(1);
    const [rangeTo, setRangeTo] = useState(5);

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleChangeForKRA = (event) => {
        setKraData({ ...KraData, [event.target.name]: event.target.value });
    };


    const handleSubmitForKpi = (e) => {
        e.preventDefault();
        setTableData([...tableData, formData]);
        setFormData({
            name: '',
            industry: '',
            category: '',
            unit: '',
            department: '',
            weightage: '',
        });
        handleClose();
    };

    const handleSubmitForKra = (e) => {
        e.preventDefault();
        setTableDataForKra([...tableDataForKra, KraData]);
        setFormData({
            category: '',
            weightage: '',
        });
        handleCloseForKra();
    };





    const handleRatingTypeChange = (e) => {
        setRatingType(e.target.value);
    };

    const handleRangeFromChange = (e) => {
        setRangeFrom(parseInt(e.target.value, 10));
    };

    const handleRangeToChange = (e) => {
        setRangeTo(parseInt(e.target.value, 10));
    };

    const renderColumns = () => {
        let columns = [];
        for (let i = rangeFrom; i <= rangeTo; i++) {
            columns.push(
                <th key={i}>{ratingType === 'Numeric' ? i : getGradeLabel(i)}</th>
            );
        }
        return columns;
    };

    const renderRows = () => {
        return (
            <tr>
                <td>Weight</td> {/* Default Goal value */}
                <td>50</td> {/* Default Goal value */}
                <td>45</td> {/* Default Achieved value */}
                {Array.from({ length: rangeTo - rangeFrom + 1 }, (_, index) => (
                    <td key={index}>
                        <input type="number" style={{ width: "90px" }} />
                        <input type="number" style={{ width: "90px" }} />

                    </td>
                ))}
            </tr>
        );
    };


    const getGradeLabel = (index) => {
        const grades = ['F', 'D', 'C', 'B', 'A'];
        return grades[index - 1] || 'A+';
    };
  
   

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(KeyResultAreasValidationSchema()),
    });

   
    const numberOptions = [
        { label: "1", value: "1" },
        { value: "2", label: '2' },
        { value: "3", label: '3' },
        { value: "4", label: '4' },
        { value: "5", label: '5' },
        { value: "6", label: '6' },
    ];

    const nos = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },

    ];
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ color: "black" }}>
                <div className="card">
                    <div className="content container-fluid">
                        <div className="card-body">
                            <div className="row">
                                
                                <div className="col-md-4">
                                    <label htmlFor="kraCheckbox" className="form-label">
                                      KEY RESULT AREA  (KRA)
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="kraCheckbox"
                                        checked={kraChecked}
                                        onChange={(e) => setKraChecked(e.target.checked)}
                                    />
                                </div>
                              
                                {kraChecked && (
                                    <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="kraCheckbox" className="form-label">
                                     Number Of  KRA
                                    </label>
                                        
                                        <Controller
                                            name="no_of_kra"
                                            control={control}
                                            render={({ field }) => (
                                                <FormSelect {...field} isClearable options={nos} />
                                            )}
                                        />
                                        <span className="text-danger"> {errors.no_of_kra?.message} </span>
                                        </div>
                                        
                                        <div className="col-md-3">

                                            <div className="col-md-4">
                                                <label>Weightage</label>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="weightageSwitch" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                <div className="col-md-3">
                                   
                                            <label htmlFor="totalKraWeightage" className="form-label">
                                                    Total KRA Weightage:
                                                 </label>
                                                 <input
                                                    type="number"
                                                    className="form-control"
                                                    id="totalKraWeightage"
                                                    value={totalKraWeightage}
                                                    onChange={(e) => setTotalKraWeightage(e.target.value)}
                                                />
                                        </div>
                                       
                                        
                                    </div>
                                    )}
                           
                            </div>
                            {kraChecked && (
                            <div className='row'>
                                <div className="col-md-3">
                                    <label>Minimum KPI under 1 KRA</label>
                                    <Controller
                                        name="min_kpi_under_1_kra"
                                        control={control}
                                        render={({ field }) => (
                                            <FormSelect {...field} isClearable options={numberOptions.slice(0, 3)} />
                                        )}
                                    />

                                    {/* <span className="text-danger"> {errors.min_kpi_under_1_kra?.message} </span> */}
                                </div>
                                <div className="col-md-3">
                                    <label>Maximum KPI under 1 KRA</label>
                                    <Controller
                                        name="max_kpi_under_1_kra"
                                        control={control}
                                        render={({ field }) => (
                                            <FormSelect {...field} isClearable options={numberOptions.slice(0, 3)} />
                                        )}
                                    />
                                    {/* <span className="text-danger"> {errors.max_kpi_under_1_kra?.message} </span> */}

                                </div>

                                </div>
                            )}


                            {kraChecked && (
                                <div className='row'>
                                    <div className="col-md-3">
                                        <label htmlFor="minKra" className="form-label">
                                                Minimum:
                                             </label>
                                             <input
                                                 type="number"
                                                 className="form-control"
                                                 id="minKra"
                                                 value={minKra}
                                                 onChange={(e) => setMinKra(e.target.value)}
                                             />
                                         </div>
                                    <div className="col-md-3">
                                            <label htmlFor="minKra" className="form-label">
                                                 Minimum:
                                             </label>
                                             <input
                                                type="number"
                                                 className="form-control"
                                                 id="minKra"
                                                value={minKra}
                                                 onChange={(e) => setMinKra(e.target.value)}
                                             />

                                    </div>
                                    

                                </div>
                                
                            )}
                            {kraChecked && (
                                
                                <div className='row'>
                                    <div className="col-md-4">
                                    
                                        <Button color="primary" onClick={handleShowKra}>
                                            Add KRA
                                        </Button>
                                    </div>

                                </div>

                            )}
                            {kraChecked && (
                                <div className='row'>
                                    <div className="col-md-5">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>KRA Category</th>
                                                    <th>KRA Weightage Percent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableDataForKra.map((kra, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{kra.category}</td>
                                                        <td>
                                                            {kra.weightage}
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            )}

                            
                        </div>
                    </div>

                    <Modal show={showKraForm} onHide={handleCloseForKra}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Key Performance Index</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmitForKra}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="category"
                                        value={KraData.category}
                                        onChange={handleChangeForKRA}
                                    />
                                </Form.Group>
                                
                                <Form.Group>
                                    <Form.Label>Weightage</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="weightage"
                                        value={KraData.weightage}
                                        onChange={handleChangeForKRA}
                                        required
                                    />
                                </Form.Group>
    
                                <Button variant="primary" type="submit">
                                    Save Changes
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                       
                   
                <div className="content container-fluid">

                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-check-label" htmlFor="keyPerformanceIndicatorKPI">KEY PERFORMANCE INDICATOR (KPI)</label>
                        </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-2">
                                <label>Weightage</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="kpiWeightageSwitch" />
                                </div>
                            </div>
                        </div>

                    <div className="row">
                        <div className="col-md-3">
                            <label>Number of KPI</label>
                            <Controller
                                name="no_of_kpi"
                                control={control}
                                render={({ field }) => (
                                    <FormSelect {...field} isClearable options={numberOptions.slice(0, 3)} />
                                )}
                            />
                            <span className="text-danger"> {errors.no_of_kpi?.message} </span>
                        </div>
                        <div className="col-md-3">
                            <label>Minimum KPI</label>
                            <Controller
                                name="min_kpi"
                                control={control}
                                render={({ field }) => (
                                    <FormSelect {...field} isClearable options={numberOptions.slice(0, 3)} />
                                )}
                            />
                            <span className="text-danger"> {errors.min_kpi?.message} </span>
                        </div>
                        <div className="col-md-3">
                            <label>Maximum KPI</label>
                            <Controller
                                name="max_kpi"
                                control={control}
                                render={({ field }) => (
                                    <FormSelect {...field} isClearable options={numberOptions.slice(0, 3)} />
                                )}
                            />
                            <span className="text-danger"> {errors.max_kpi?.message} </span>
                    </div>
                            
                 </div>
                  
                

                    <div className="row">
                        <div className="col-md-2">
                            <label>Unit</label>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="unitSwitch" />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label>Rating based on unit</label>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="ratingUnitSwitch" />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label>Cascade</label>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="cascadeSwitch" />
                            </div>
                        </div>
                        </div>
                        

                        <div className="row">
                            <div className="col-md-2">
                                <label>Cascade</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="cascadeSwitch" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label>Allow editing for Cascade KPIs </label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="unitSwitch" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label>Approval for Cascaded KPIs</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="ratingUnitSwitch" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <label>Library</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="cascadeSwitch" />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <Button variant="primary" onClick={handleShow}>
                                    Add KPI
                                </Button>
                                
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className="col-md-8">
                                {/* Display Table */}
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Name</th>
                                            <th>Industry</th>
                                            <th>KRA Category</th>
                                            <th>Unit</th>
                                            <th>Department</th>
                                            <th>Weightage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((data, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.name}</td>
                                                <td>{data.industry}</td>
                                                <td>{data.category}</td>
                                                <td>{data.unit}</td>
                                                <td>{data.department}</td>
                                                <td>{data.weightage}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                           
                                </div>
                        </div>
                        
                    </div>
                    

                    <div className="content container-fluid">
                        <div className='row'>
                            <div className="col-md-4">
                                <Form.Group controlId="ratingType">
                                    <Form.Label>Rating</Form.Label>
                                    <Form.Control as="select" value={ratingType} onChange={handleRatingTypeChange}>
                                        <option value="Numeric">Numeric</option>
                                        <option value="Percentage">Percentage</option>
                                        <option value="Grade">Grade</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                    <Form.Group as={Col} controlId="rangeFrom">
                                        <Form.Label>Range From</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={rangeFrom}
                                            onChange={handleRangeFromChange}
                                            min="1"
                                            max={rangeTo - 1}
                                        />
                                    </Form.Group>

                            </div>
                            <div className="col-md-4">
                                <Form.Group as={Col} controlId="rangeTo">
                                    <Form.Label>Range To</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={rangeTo}
                                        onChange={handleRangeToChange}
                                        min={rangeFrom + 1}
                                        max="5"
                                    />
                                </Form.Group>

                            </div>
                        </div>
                    </div>
                    <div className="content container-fluid">
                        <div className='row'>
                            <div className="col-md-24">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Value</th>
                                            <th>Goal</th>
                                            <th>Achieved</th>
                                            {renderColumns()}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderRows()}
                                    </tbody>
                                </Table>
                             </div>
                        </div>
                    </div>
                       

                        

                           

                        {/* <Row>{renderColumns()}</Row> */}
                                        
                </div>

            </div>
            
            

            {/* model for KrA */}


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Key Performance Index</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitForKpi}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Industry</Form.Label>
                            <Form.Control as="select" name="industry" value={formData.industry} onChange={handleChange}>
                                <option value="">Select Industry</option>
                                <option value="Automobile">Automobile</option>
                                <option value="FMCG">FMCG</option>
                                <option value="IT">Information Technology</option>
                                <option value="Pharmaceutical">Pharmaceutical</option>
                                <option value="BFSI">BFSI</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>KRA Category</Form.Label>
                            <Form.Control
                                type="text"
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Unit</Form.Label>
                            <Form.Control
                                type="text"
                                name="unit"
                                value={formData.unit}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Department</Form.Label>
                            <Form.Control
                                type="text"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Weightage</Form.Label>
                            <Form.Control
                                type="number"
                                name="weightage"
                                value={formData.weightage}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            
        
        </form>
    );
}

export default KeyResultAreas;
