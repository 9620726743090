import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  setAlias,
  setTargetMultiYear,
  toggleSetting,
  toggleSettingProperty,
  toggleTargetAnnual,
  toggleInterimProperty,
  toggleReviewLevel,
  togglePeriodicProperty,
  setSettingDate,
  setReviewLevel,
} from "../../../store/config_top_down";
import { useDispatch } from "react-redux";
import { getMultiYearRanges } from "../../../helpers";
import FormSelect from "../../../components/Inputs/FormSelect";
import DatePicker from "react-datepicker";

const topDownFormSchema = Yup.object().shape({
  category: Yup.string()
    .transform((v) => (v ? v.value : null))
    .required("Category is required"),
  goalType: Yup.string()
    .transform((v) => (v ? v.value : null))
    .oneOf(["Individual", "Functional", "Common"], "Invalid Goal type")
    .required("Goal Type is required"),
  weightage: Yup.mixed()
    .test("is-number", "Invalid (1 <= Weightage <= 100)", function (value) {
      let num;
      if (typeof value === "object" && value !== null) {
        num = value.value;
      } else {
        num = value;
      }

      return Yup.number().isValidSync(num) && num > 0 && num <= 100;
    })
    .transform(function (value) {
      if (typeof value === "object" && value !== null) {
        return value.value;
      }
      return value;
    })
    .required("Weightage is required"),
  description: Yup.string().required("Description is required"),
});

const ThirdScreen = (props) => {
  const topDownConfig = useSelector((state) => state.topDownConfig);
  const dispatch = useDispatch();
  const yearRanges = getMultiYearRanges({ before: 0, after: 6 }).map((r) => ({
    label: r,
    value: r,
  }));

  const { handleSubmit } = useForm({
    resolver: yupResolver(topDownFormSchema),
  });

  const saveHandler = (data) => {
    console.log(topDownConfig);
    props.saveHandler(data);
  };

  const reviewLevelCount = Math.max(
    ...Object.keys(topDownConfig.review_hierarchy).map(
      (p) => topDownConfig.review_hierarchy[p],
    ),
  );

  const reviewLevels = [];
  for (let i = 1; i <= reviewLevelCount; i++) {
    reviewLevels.push(
      <div className="col-md-3" key={i}>
        <div className="card">
          <div className="card-body">
            <h3>{`Level-${i}`}</h3>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">Comments</div>
              <div className="col-md-4">Grading</div>
            </div>
            {(topDownConfig.review_hierarchy.periodic >= i) &&
              topDownConfig.main.periodic.enabled && (
                <div className="row">
                  <div className="col-md-4">Periodic</div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "periodic",
                              property: "comment",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].periodic.comment
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "periodic",
                              property: "grading",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].periodic.grading
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              )}
            {(topDownConfig.review_hierarchy.interim >= i) &&
              topDownConfig.main.interim.enabled && (
                <div className="row">
                  <div className="col-md-4">Interim</div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "interim",
                              property: "comment",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].interim.comment
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "interim",
                              property: "grading",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].interim.grading
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              )}

            {(topDownConfig.review_hierarchy.target >= i) &&
              topDownConfig.main.target.enabled && (
                <div className="row">
                  <div className="col-md-4">Target</div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "target",
                              property: "comment",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].target.comment
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        onChange={() =>
                          dispatch(
                            toggleReviewLevel({
                              level: i,
                              type: "target",
                              property: "grading",
                            }),
                          )
                        }
                        checked={
                          topDownConfig.review_level_setting[i].target.grading
                        }
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>,
    );
  }

  return (
    <div className="card">
      <div className="content container-fluid">
        <div className="card-body">
          <h3 className="border-bottom border-2 my-2">Rating</h3>
          <form onSubmit={saveHandler}>
            <div className="row fw-bold my-2">
              <div className="col">Mandatory for</div>
              <div className="col">Alias</div>
              <div className="col">Enable/Disable</div>
              <div className="col">Comments</div>
              <div className="col">Grading</div>
            </div>
            {Object.keys(topDownConfig.main).map((setting) => {
              const values = topDownConfig.main[setting];

              return (
                <div className="row pb-2" key={setting}>
                  <div className="col">{values.label}</div>
                  <div className="col">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        dispatch(
                          setAlias({ setting: setting, alias: e.target.value }),
                        )
                      }
                      value={values.alias}
                      type="text"
                    />
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      {values.enabled !== null && (
                        <input
                          className="form-check-input"
                          onChange={() =>
                            dispatch(toggleSetting({ setting: setting }))
                          }
                          checked={values.enabled}
                          type="checkbox"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      {values.comment !== null && (
                        <input
                          className="form-check-input"
                          onChange={() =>
                            dispatch(
                              toggleSettingProperty({
                                setting: setting,
                                property: "comment",
                              }),
                            )
                          }
                          checked={values.comment}
                          type="checkbox"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      {values.grading !== null && (
                        <input
                          className="form-check-input"
                          onChange={() =>
                            dispatch(
                              toggleSettingProperty({
                                setting: setting,
                                property: "grading",
                              }),
                            )
                          }
                          checked={values.grading}
                          type="checkbox"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <section className="mt-5">
              {/* Dropdown for Target */}
              <div className="d-flex flex-row gap-3">
                {topDownConfig.main.target.enabled && (
                  <div className="card me-2 w-25">
                    <div className="card-body">
                      <div className="row">
                        <h4>Target</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Annual</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-check-input"
                            onChange={() => {
                              dispatch(toggleTargetAnnual());
                            }}
                            checked={topDownConfig.target.annual}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Multi Years</label>
                        </div>
                        <div className="col-md-6">
                          <FormSelect
                            options={yearRanges}
                            value={{
                              label: topDownConfig.target.multiyear,
                              value: topDownConfig.target.multiyear,
                            }}
                            onChange={(e) => {
                              if (e) {
                                dispatch(setTargetMultiYear(e.value));
                              } else {
                                dispatch(setTargetMultiYear(null));
                              }
                            }}
                            isDisabled={topDownConfig.target.annual}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {topDownConfig.main.interim.enabled && (
                  <div className="card me-2 w-25">
                    <div className="card-body">
                      <div className="row">
                        <h4>Rating Interim</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <label>Monthly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                toggleInterimProperty({ property: "monthly" }),
                              )
                            }
                            checked={topDownConfig.interim.monthly}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>Quarterly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                toggleInterimProperty({
                                  property: "quarterly",
                                }),
                              )
                            }
                            checked={topDownConfig.interim.quarterly}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>Half-Yearly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                toggleInterimProperty({
                                  property: "half_yearly",
                                }),
                              )
                            }
                            checked={topDownConfig.interim.half_yearly}
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {topDownConfig.main.periodic.enabled && (
                  <div className="card me-2 w-25">
                    <div className="card-body">
                      <div className="row">
                        <h4>Periodic Data Entry</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <label>Daily</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                togglePeriodicProperty({ property: "daily" }),
                              )
                            }
                            checked={topDownConfig.periodic.daily}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>Weekly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                togglePeriodicProperty({ property: "weekly" }),
                              )
                            }
                            checked={topDownConfig.periodic.weekly}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>Monthly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                togglePeriodicProperty({ property: "monthly" }),
                              )
                            }
                            checked={topDownConfig.periodic.monthly}
                            type="checkbox"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>Fortnightly</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            className="form-check-input"
                            onChange={() =>
                              dispatch(
                                togglePeriodicProperty({
                                  property: "fortnightly",
                                }),
                              )
                            }
                            checked={topDownConfig.periodic.fortnightly}
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section>
              <div className="row fw-bold">
                <div className="col-md-2">Dates</div>
                <div className="col-md-2">Set</div>
                <div className="col-md-2">Approve</div>
              </div>
              <div className="row">
                <div className="col-md-2">Goal Setting</div>
                <div className="col-md-2">
                  <div className="cal-icon focused">
                    <DatePicker
                      className="form-control floating datetimepicker"
                      selected={
                        topDownConfig.dates.goalsetting.set &&
                        new Date(topDownConfig.dates.goalsetting.set)
                      }
                      onChange={(dt) => {
                        dispatch(
                          setSettingDate({
                            setting: "goalsetting",
                            property: "set",
                            value: dt && dt.toISOString(),
                          }),
                        );
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="cal-icon focused">
                    <DatePicker
                      className="form-control floating datetimepicker"
                      selected={
                        topDownConfig.dates.goalsetting.approve &&
                        new Date(topDownConfig.dates.goalsetting.approve)
                      }
                      onChange={(dt) => {
                        dispatch(
                          setSettingDate({
                            setting: "goalsetting",
                            property: "approve",
                            value: dt && dt.toISOString(),
                          }),
                        );
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
              </div>
              {topDownConfig.main.periodic.enabled && (
                <div className="row">
                  <div className="col-md-2">Periodic</div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.periodic.set &&
                          new Date(topDownConfig.dates.periodic.set)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "periodic",
                              property: "set",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.periodic.approve &&
                          new Date(topDownConfig.dates.goalsetting.set)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "periodic",
                              property: "approve",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                </div>
              )}

              {topDownConfig.main.interim.enabled && (
                <div className="row">
                  <div className="col-md-2">Interim</div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.interim.set &&
                          new Date(topDownConfig.dates.interim.set)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "interim",
                              property: "set",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.interim.approve &&
                          new Date(topDownConfig.dates.interim.approve)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "interim",
                              property: "approve",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                </div>
              )}

              {topDownConfig.main.target.enabled && (
                <div className="row">
                  <div className="col-md-2">Target</div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.target.set &&
                          new Date(topDownConfig.dates.target.set)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "target",
                              property: "set",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="cal-icon focused">
                      <DatePicker
                        className="form-control floating datetimepicker"
                        selected={
                          topDownConfig.dates.target.approve &&
                          new Date(topDownConfig.dates.target.approve)
                        }
                        onChange={(dt) => {
                          dispatch(
                            setSettingDate({
                              setting: "target",
                              property: "approve",
                              value: dt && dt.toISOString(),
                            }),
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                </div>
              )}
            </section>

            {
              <section id="review-hierarchy" className="mt-5">
                <div className="row">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Review Hierarchy</h4>
                      </div>
                      <div className="col-md-6">
                        <h4>Level</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">Periodic</div>

                      <div className="col-md-6">
                        <input
                          type="number"
                          value={topDownConfig.review_hierarchy.periodic}
                          onChange={(e) => {
                            dispatch(
                              setReviewLevel({
                                property: "periodic",
                                value: e.target.value,
                              }),
                            );
                          }}
                          min="1"
                          max="2"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">Interim</div>

                      <div className="col-md-6">
                        <input
                          type="number"
                          value={topDownConfig.review_hierarchy.interim}
                          onChange={(e) => {
                            dispatch(
                              setReviewLevel({
                                property: "interim",
                                value: e.target.value,
                              }),
                            );
                          }}
                          min="1"
                          max="2"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">Target</div>

                      <div className="col-md-6">
                        <input
                          type="number"
                          value={topDownConfig.review_hierarchy.target}
                          onChange={(e) => {
                            dispatch(
                              setReviewLevel({
                                property: "target",
                                value: e.target.value,
                              }),
                            );
                          }}
                          min="1"
                          max="2"
                        />
                      </div>
                    </div>
                  </div>

                  {reviewLevels}
                </div>
              </section>
            }

            <div className="row">
              <div className="col">
                <div className="d-flex gap-3 justify-content-end">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                  <Button variant="secondary">Cancel</Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ThirdScreen;
