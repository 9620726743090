import React, { useState } from "react";

const GoalsReview2 = () => {
  const [selfRating, setSelfRating] = useState("");
  const [managerRating, setManagerRating] = useState("");
  const [selfComment, setSelfComment] = useState("");
  const [managerComment, setManagerComment] = useState("");

  const RatingInput = ({ value, onChange }) => (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
    >
      {[1, 2, 3, 4, 5].map((num) => (
        <label key={num} style={{ marginRight: "10px" }}>
          <input
            type="radio"
            value={num}
            checked={value === num.toString()}
            onChange={() => onChange(num.toString())}
          />
          {num}
        </label>
      ))}
    </div>
  );

  const EditableField = ({ value, onChange, placeholder }) => (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
    >
      <span style={{ marginRight: "5px" }}>✏️</span>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        style={{ width: "100%" }}
      />
    </div>
  );

  return (
    <div className="page-wrapper" style={{ color: "black" }}>
      <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <input
            type="text"
            placeholder="EMPLOYEE NAME"
            style={{ width: "30%" }}
          />
          <input
            type="text"
            placeholder="EMPLOYEE CODE"
            style={{ width: "30%" }}
          />
          <input
            type="text"
            placeholder="DESIGNATION"
            style={{ width: "30%" }}
          />
          <input type="text" value="Q1" readOnly style={{ width: "5%" }} />
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>SELF RATING</th>
              <th>SELF COMMENT</th>
              <th>MANAGER RATING</th>
              <th>MANAGER COMMENT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <RatingInput value={selfRating} onChange={setSelfRating} />
                <EditableField
                  value={selfRating}
                  onChange={setSelfRating}
                  placeholder="Enter rating"
                />
              </td>
              <td>
                <EditableField
                  value={selfComment}
                  onChange={setSelfComment}
                  placeholder="GOOD"
                />
                <EditableField
                  value=""
                  onChange={() => {}}
                  placeholder="TARGET ACHIEVED"
                />
              </td>
              <td>
                <RatingInput
                  value={managerRating}
                  onChange={setManagerRating}
                />
                <EditableField
                  value={managerRating}
                  onChange={setManagerRating}
                  placeholder="Enter rating"
                />
              </td>
              <td>
                <EditableField
                  value={managerComment}
                  onChange={setManagerComment}
                  placeholder="GOOD"
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ height: "100px" }}></td>
              <td colSpan="2" style={{ height: "100px" }}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoalsReview2;
