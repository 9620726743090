import { createSlice } from "@reduxjs/toolkit";

const initial = {
  value: {}
};

export const krasSlice = createSlice({
  name: "kras",
  initialState: initial,
  reducers: {
    save: (state, {payload}) => {
      state.value = payload.kras;
    },
    add: (state, {payload}) => {
      const kras = {...state.value};
      kras[payload.newKRA.kra_id] = payload.newKRA;
      state.value = kras;
    },
    edit: (state, {payload}) => {
      const kras = {...state.value};

      kras[payload.kra_id] = payload;
      state.value = kras;
    }
  }

}); 

export const { add, edit, save } = krasSlice.actions;
export default krasSlice.reducer;
