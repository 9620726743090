import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const EightScreen = () => {
    const [goals, setGoals] = useState([
        { id: 1, description: 'Employee', target: '', remarks: '' },
        { id: 2, description: 'Manager', target: '', remarks: '' },
    ]);

    const [showModal, setShowModal] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [formData, setFormData] = useState({
        learningName: '',
        content: '',
        dateTime: '',
        location: '',
        status: '',
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPrograms([...programs, formData]);
        setFormData({
            learningName: '',
            content: '',
            dateTime: '',
            location: '',
            status: '',
        });
        handleClose();
    };


  

    return (
        <div>
             <div className="card">
                <div className="card-body">
            <div className="container-fluid">
           
                        <div className="row">
                            <div className="col-md-1">

                                    <label>Part</label>
                            </div>
                            
                            <div className="col-md-2">
                                <Form.Group controlId="ratingType">
                                    <Form.Control as="select">
                                        <option value="2">2</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-1">
                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="ratingType">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control as="select">
                                        <option value="Numeric">Learning</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-1">
                                <Form.Label>.</Form.Label>

                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-1">

                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Mandatory</Form.Label>

                                </Form.Group>
                            </div>
                            <div className="col-md-1">

                                <Form.Group controlId="formMandatory">
                                    <Form.Check
                                        type="switch"
                                        id="mandatory-switch"
                                        label=""
                                        name="mandatory"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-2">
                                <Button variant="dark" onClick={handleShow}>
                                    + Add
                                </Button>

                            </div>

                        </div>



                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-12"></div>
                            <h5>Program</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr.no</th>
                                        <th>Learning Name</th>
                                        <th>Content</th>
                                        <th>Program Date & Time</th>
                                        <th>Location</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {programs.map((program, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{program.learningName}</td>
                                            <td>{program.content}</td>
                                            <td>{program.dateTime}</td>
                                            <td>{program.location}</td>
                                            <td>{program.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>

                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-3">
                                <label>Employee can out Learning Program</label>
                            </div>
                            <div className="col-md-3">
                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Minimum</Form.Label>
                                    <input type="number"/>

                                </Form.Group>

                            </div>
                            <div className="col-md-2">
                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Maximum</Form.Label>
                                    <input type="number" />

                                </Form.Group>

                            </div>
                        </div>
                    

                        
                        
                        <div className="row" style={{ marginTop: 15 }}>
                        <div className="col">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Remark</th>
                                        <th>Grading</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goals.map(goal => (
                                        <tr key={goal.id}>
                                            <td>{goal.description}</td>
                                            <td>
                                                <Form.Group controlId="formPart">
                                                    <Form.Check type="checkbox" />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group controlId="formPart">
                                                    <Form.Check type="checkbox" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button  type="submit" className="btn btn-success">Save</button>
                            </div>
                        </div>
            </div>
                    
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Program</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formlearningName">
                                    <Form.Label>Learning Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="learningName"
                                        value={formData.learningName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formcontent">
                                    <Form.Label>Content</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="content"
                                        value={formData.content}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>


                                <Form.Group controlId="formDateTime">
                                    <Form.Label>Program Date & Time</Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        name="dateTime"
                                        value={formData.dateTime}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLocation">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit" style={{ marginTop: 10 }}>
                                    Add Program
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
            </div>
            </div>
            </div>
    );
};

export default EightScreen;