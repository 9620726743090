import React from "react";
import Profile from "../../../components/User/Profile";
import colors from "../../../assets/scss/base/colors.scss";

function convertToPascalCase(inputString) {
  if (typeof inputString === "string") {
    const words = inputString.replace(/_/g, " ").split(/\s+/);

    // Capitalize the first letter of each word and join them
    const pascalCase = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return pascalCase;
  }

  return inputString;
}

function textToLogo(txt) {
  let iconHtml;
  
  switch(txt) {
    case "financial_goals": 
      iconHtml = <i className="fas fa-dollar-sign " style={{ color: colors.$__secondarycolor }}></i>
    break;

    case "customer_goals": 
      iconHtml = <i className="fas fa-users"></i>
    break;

    case "business_goals": 
      iconHtml = <i className="fas fa-building"></i>
    break;

    case "process_goals": 
      iconHtml = <i className="fas fa-tasks"></i>
    break;

    default:
      iconHtml = <i className="fas fa-dollar-sign"></i>
  }

  return iconHtml;
}

const GoalsReview1 = () => {
  // TODO: fetch from the API
  const kras = {
    financial_goals: [
      {
        kpi: "Revenue Growth",
        unit: "Percentage",
        weightage: 30,
        value: 15,
        tenure: "Quarterly",
      },
      {
        kpi: "Cost Reduction",
        unit: "INR",
        weightage: 25,
        value: 1000000,
        tenure: "Yearly",
      },
      {
        kpi: "Profit Margin",
        unit: "Percentage",
        weightage: 25,
        value: 20,
        tenure: "Quarterly",
      },
      {
        kpi: "Return on Investment",
        unit: "Ratio",
        weightage: 20,
        value: 1.5,
        tenure: "Yearly",
      },
    ],
    customer_goals: [
      {
        kpi: "Customer Satisfaction Score",
        unit: "Scale (1-10)",
        weightage: 35,
        value: 8.5,
        tenure: "Monthly",
      },
      {
        kpi: "Customer Retention Rate",
        unit: "Percentage",
        weightage: 30,
        value: 90,
        tenure: "Quarterly",
      },
      {
        kpi: "Net Promoter Score",
        unit: "Scale (-100 to 100)",
        weightage: 20,
        value: 60,
        tenure: "Monthly",
      },
      {
        kpi: "New Customer Acquisition",
        unit: "Count",
        weightage: 15,
        value: 500,
        tenure: "Quarterly",
      },
    ],
    business_goals: [
      {
        kpi: "Market Share",
        unit: "Percentage",
        weightage: 30,
        value: 25,
        tenure: "Yearly",
      },
      {
        kpi: "Product Launch Timeline",
        unit: "Days",
        weightage: 25,
        value: 90,
        tenure: "Quarterly",
      },
      {
        kpi: "Employee Satisfaction",
        unit: "Scale (1-5)",
        weightage: 20,
        value: 4.2,
        tenure: "Yearly",
      },
      {
        kpi: "Brand Awareness",
        unit: "Percentage",
        weightage: 25,
        value: 75,
        tenure: "Quarterly",
      },
    ],
    process_goals: [
      {
        kpi: "Production Efficiency",
        unit: "Percentage",
        weightage: 30,
        value: 85,
        tenure: "Monthly",
      },
      {
        kpi: "Defect Rate",
        unit: "Percentage",
        weightage: 25,
        value: 2,
        tenure: "Monthly",
      },
      {
        kpi: "On-Time Delivery",
        unit: "Percentage",
        weightage: 25,
        value: 95,
        tenure: "Quarterly",
      },
      {
        kpi: "Inventory Turnover",
        unit: "Ratio",
        weightage: 20,
        value: 6.5,
        tenure: "Yearly",
      },
    ],
  };

  const yearSpan = "2024-2025";

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row h-25">
          <h3 className="page-title">Goals Review</h3>
        </div>

        <div className="user-name mt-2 mb-3">Review Year: {yearSpan}</div>

        <Profile />

        <div className="card mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Goals</th>
                      <th>Units</th>
                      <th>Weightage</th>
                      <th>Value</th>
                      <th>
                        {" "}
                        (1) <br /> Unsatisfactory <br /> Performance{" "}
                      </th>
                      <th>
                        {" "}
                        (2) <br /> Needs <br /> Improvement{" "}
                      </th>
                      <th>
                        {" "}
                        (3) <br /> Standard <br /> Performance{" "}
                      </th>
                      <th>
                        {" "}
                        (4) <br /> Superior <br /> Performance{" "}
                      </th>
                      <th>
                        {" "}
                        (5) <br /> Outstanding <br /> Performance{" "}
                      </th>
                      <th>Tenure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(kras).map((goalType) => {
                      const item = kras[goalType];
                      return (
                        <>
                          <tr key={goalType}>
                            <td colSpan="100%" className="table-primary">
                              { textToLogo(goalType)}
                              <span style={{ display: "border-box"}} className="ms-2 fs-6">{convertToPascalCase(goalType)}</span>
                            </td>
                          </tr>
                          {item.map((kpi) => (
                            <tr key={kpi.kpi + "_" + goalType}>
                              <td>{kpi.kpi}</td>
                              <td>{kpi.unit}</td>
                              <td>{kpi.weightage}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.value}</td>
                              <td>{kpi.tenure}</td>
                            </tr>
                          ))}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsReview1;
