import { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import FormSelect from "../Inputs/FormSelect";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

const answerTypes = ["Fixed Ratings", "Custom Ratings", "Objective", "Text"];
const fixedRatings = [
  "Not Satisfactory",
  "Satisfactory",
  "Good",
  "Very Good",
  "Exceptional",
];

const initial = {
  question: "",
  answer_type: "",
  levels: [],
};

const objectiveRatings = ["Yes (Agree)", "No (Disagree)"];

const questionValidationSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  answer_type: Yup.string()
    .transform((v) => (v ? v.value : null))
    .required("Please select and answer type"),
});

const Question = (props) => {
  const [questionSetup, updateQuestionSetup] = useState(initial);
  const [editCustomRatingIndex, setUpdateCustomRatingIndex] = useState(null);
  const editRef = useRef();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(questionValidationSchema),
  });

  useEffect(() => {
    if(editRef.current && editRef.current !== null) {
      editRef.current.focus();
    }
  }, [editCustomRatingIndex]);

  const updateAnswerType = (type) => {
    if (!type) {
      return;
    }
    const setup = { ...questionSetup };
    setup.answer_type = type.value;

    switch (type.value.toLowerCase()) {
      case "fixed ratings":
        setup.levels = [...fixedRatings];
        break;
      case "custom ratings":
        setup.levels = [...fixedRatings];
        break;
      case "objective":
        setup.levels = [...objectiveRatings];
        break;

      default:
        setup.levels = [];
    }

    updateQuestionSetup(setup);
  };

  const resetModal = (next) => {
    updateQuestionSetup(props.initial);
    setUpdateCustomRatingIndex(null);

    setValue("question", null);
    setValue("answer_type", null);

    if (next && typeof next === "function") {
      next();
    }
  };

  const updateCustomRating = (idx, value) => {
    const setup = { ...questionSetup };
    setup.levels[idx] = value;

    updateQuestionSetup(setup);
  };

  const checkEnter = (idx, e) => {
    if (e.key === "Enter") {
      updateCustomRating(idx, e.target.value);
      setUpdateCustomRatingIndex(null);
    }
  };

  const saveHandler = (data) => {
    const inputs = { ...data };

    inputs.levels = questionSetup.levels;
    inputs.idx = questionSetup.idx;

    resetModal();

    props.saveHandler(inputs);
  };

  const watchAnswerType = watch("answer_type");

  useEffect(() => {
    updateAnswerType(watchAnswerType);
  }, [watchAnswerType]);

  useEffect(() => {
    if (props.initial && props.initial.idx !== undefined) {
      updateQuestionSetup({ ...props.initial });
      setValue("question", props.initial.question);
    }
  }, []);

  return (
    <Modal show={props.show} onHide={() => resetModal(props.onHide)}>
      <Modal.Header>
        <h4 className="modal-title">Create Question</h4>

        <button
          type="button"
          className="btn-close float-right"
          aria-label="Close"
          onClick={() => resetModal(props.onHide)}
          value={questionSetup.question}
        >
          <i className="fas fa-close"></i>
        </button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(saveHandler)}>
          <div className="row">
            <div className="col mb-3">
              <label className="col-form-label">Question</label>
              <input
                type="text"
                className="form-control"
                {...register("question")}
                placeholder="Type your question..."
              />
              <span className="text-danger"> {errors.question?.message} </span>
            </div>
          </div>

          <div className="row">
            <div className="col mb-3">
              <label className="col-form-label">Answer Type</label>
              <Controller
                name="answer_type"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    value={
                      questionSetup.answer_type && {
                        name: questionSetup.answer_type,
                        label: questionSetup.answer_type,
                      }
                    }
                    options={answerTypes.map((t) => ({ label: t, value: t }))}
                    placeholder="Question type.."
                  />
                )}
              />
              <span className="text-danger">
                {" "}
                {errors.answer_type?.message}{" "}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col mb-3">
              <label className="col-form-label">
                {questionSetup.answer_type} (Preview)
              </label>
              {questionSetup.answer_type.toLowerCase() === "fixed ratings" && (
                <ul className="list-group">
                  {fixedRatings.map((r) => (
                    <li key={r} className="list-group-item">
                      <label>{r}</label>
                    </li>
                  ))}
                </ul>
              )}
              {questionSetup.answer_type.toLowerCase() === "objective" && (
                <ul className="list-group">
                  {objectiveRatings.map((r) => (
                    <li key={r} className="list-group-item">
                      {r}
                    </li>
                  ))}
                </ul>
              )}

              {questionSetup.answer_type.toLowerCase() === "text" && (
                <textarea
                  className="form-control"
                  placeholder="Please share your feedback"
                  disabled
                ></textarea>
              )}

              {questionSetup.answer_type.toLowerCase() === "custom ratings" && (
                <ul className="list-group">
                  {questionSetup.levels.map((r, idx) => (
                    <li key={idx} className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">
                          {editCustomRatingIndex === idx ? (
                            <input
                              type="text"
                              value={r}
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                updateCustomRating(idx, e.target.value);
                              }}
                              onKeyDown={(e) => {
                                checkEnter(idx, e);
                              }}
                              ref={editRef}
                            />
                          ) : (
                            <span
                              className="cursor-pointer"
                              onClick={() => setUpdateCustomRatingIndex(null)}
                            >
                              {r}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          {editCustomRatingIndex !== idx && (
                            <button
                              className="btn btn-light btn-sm"
                              onClick={() => {
                                setUpdateCustomRatingIndex(idx);
                                console.log(editRef);
                              }}
                            >
                              Edit
                            </button>
                          )}
                          {editCustomRatingIndex === idx && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => setUpdateCustomRatingIndex(null)}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col mb-3">
              <label className="col-form-label">&nbsp;</label>
              <br />
              <Button
                variant="secondary"
                onClick={() => resetModal(props.closeModal)}
              >
                Close
              </Button>
              <input
                className="btn btn-primary ms-3"
                type="submit"
                value={questionSetup.idx === undefined ? "Add" : "Update"}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Question;
