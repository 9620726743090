/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../axios";
import { use } from "i18next";

const Logout = () => {
  const details = localStorage.getItem("loginDetails");
  localStorage.removeItem("loginDetails");
  // Forward to the login page
  const navigate = useNavigate();

  try {
    const response = axios
      .post(
        "logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(details).access_token}`,
          },
        }
      )
      .then((response) => {});
  } catch (error) {
    console.log(error);
  }

  setTimeout(() => navigate("/"), 3000);

  // Redirect to the login page

  return (
    <div>
      <div className="account-page">Logging out...</div>
    </div>
  );
};

export default Logout;
