import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar_02, Avatar_16 } from "../../Routes/ImagePath";

const Profile = () => {
  const employeeDetails = useSelector( state => state.user.value);

  return (<div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-view">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      <Link to="#">
                        <img src={Avatar_02} alt={employeeDetails.name} />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="profile-info-left">
                          <h3 className="user-name m-t-0 mb-0">
                            {employeeDetails.name}
                          </h3>
                          <h6 className="text-muted">{employeeDetails.role}</h6>
                          <small className="text-muted">
                            {employeeDetails.jobTitle}
                          </small>
                          <div className="staff-id">
                            Employee ID : {employeeDetails.code}
                          </div>
                          <div className="small doj text-muted">
                            Date of Join : {employeeDetails.dateOfJoin}
                          </div>
                          <div className="user-name mt-2">
                            Department: {employeeDetails.department}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Phone:</div>
                            <div className="text">
                              <Link to={`tel:${employeeDetails.phone}`}>
                                {employeeDetails.phone}
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="title">Email:</div>
                            <div className="text">
                              <Link to={`mailto:${employeeDetails.email}`}>
                                {employeeDetails.email}
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="title">Reports to:</div>
                            <div className="text">
                              <div className="avatar-box">
                                <div className="avatar avatar-xs">
                                  <img src={Avatar_16} alt={employeeDetails.email} />
                                </div>
                              </div>
                              {employeeDetails.manager}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)
}

export default Profile;
