import React, { useState } from "react";

const GoalsCreation2 = () => {
  const [kraCategory, setKraCategory] = useState("");
  const [goalType, setGoalType] = useState("");
  const [kpis, setKpis] = useState([]);

  const handleAddKPI = () => {
    setKpis([...kpis, { description: "", unit: "", weightage: "", value: "" }]);
  };

  const updateKPI = (index, field, value) => {
    const updatedKpis = [...kpis];
    updatedKpis[index][field] = value;
    setKpis(updatedKpis);
  };

  return (
    <div className="page-wrapper" style={{ color: "black" }}>
      <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <input type="text" placeholder="EMPLOYEE NAME" />
          <input type="text" placeholder="EMPLOYEE CODE" />
          <input type="text" placeholder="DEPARTMENT" />
          <input type="text" placeholder="MANAGERS NAME" />
          <select defaultValue="2023-2024">
            <option value="2023-2024">2023-2024</option>
          </select>
        </div>

        <div style={{ marginBottom: "10px" }}>
          Another KRA category Selection
        </div>

        <button
          style={{
            backgroundColor: "#ff6b6b",
            color: "white",
            padding: "10px",
            border: "none",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          + ADD KRA
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <select
            value={kraCategory}
            onChange={(e) => setKraCategory(e.target.value)}
            disabled={kraCategory !== ""}
          >
            <option value="">KRA CATEGORY</option>
            <option value="PEOPLE">PEOPLE</option>
            <option value="BUSINESS">BUSINESS</option>
            <option value="CUSTOMER">CUSTOMER</option>
            <option value="PROCESS">PROCESS</option>
          </select>
          <select
            value={goalType}
            onChange={(e) => setGoalType(e.target.value)}
          >
            <option value="">GOAL TYPE</option>
            <option value="INDIVIDUAL">INDIVIDUAL</option>
            <option value="FUNCTIONAL">FUNCTIONAL</option>
            <option value="COMMON">COMMON</option>
          </select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label>KRA CATEGORY: </label>
          <input type="text" value={kraCategory} readOnly />
          <label> KRA DESCRIPTION: </label>
          <input type="text" placeholder="(TEXT)" />
        </div>

        <button
          onClick={handleAddKPI}
          style={{
            backgroundColor: "#ff6b6b",
            color: "white",
            padding: "10px",
            border: "none",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          + ADD KPI
        </button>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>KPI DESCRIPTION</th>
              <th>UNIT</th>
              <th>WEIGHTAGE</th>
              <th>VALUE</th>
            </tr>
          </thead>
          <tbody>
            {kpis.map((kpi, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={kpi.description}
                    onChange={(e) =>
                      updateKPI(index, "description", e.target.value)
                    }
                  />
                </td>
                <td>
                  <select
                    value={kpi.unit}
                    onChange={(e) => updateKPI(index, "unit", e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="UNITS">UNITS</option>
                    <option value="DAYS">DAYS</option>
                    <option value="DATE">DATE</option>
                    <option value="WEIGHT">WEIGHT</option>
                    <option value="RATIO">RATIO</option>
                    <option value="VALUE(INR)">VALUE(INR)</option>
                  </select>
                </td>
                <td>
                  <select
                    value={kpi.weightage}
                    onChange={(e) =>
                      updateKPI(index, "weightage", e.target.value)
                    }
                  >
                    {[10, 20, 30, 40, 50, 60].map((w) => (
                      <option key={w} value={w}>
                        {w}%
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <td>
                    <input
                      type="text"
                      value={kpi.value}
                      onChange={(e) =>
                        updateKPI(index, "value", e.target.value)
                      }
                    />
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ marginTop: "20px" }}>
          <select>
            <option value="">WEIGHTAGE</option>
            {[10, 20, 30, 40, "*ADD"].map((w) => (
              <option key={w} value={w}>
                {w}
                {typeof w === "number" ? "%" : ""}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default GoalsCreation2;
