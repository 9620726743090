import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormSelect from "../../../components/Inputs/FormSelect";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { api_base_url } from "../../../base_urls";
import axios from "../../../axios";
import DatePicker from "react-datepicker";
import DateInput from "../../../components/Inputs/DateInput";

const ConfigMainValidationSchema = () =>
  Yup.object().shape({
    from_date: Yup.string().required("From date is required").nullable(),
    to_date: Yup.string().required("To date is required").nullable(),
    method: Yup.string()
      .transform((v) => (v ? v.value : null))
      .required("Method is required"),
    industry: Yup.string()
      .transform((v) => (v ? v.value : null))
      .required("Industry is required"),
  });

const ConfigMain = () => {
  const navigate = useNavigate();
  const [isMultipleYear, setMultipleYear] = useState("single");
  const [dates, setDates] = useState({ to_date: null, from_date: null });
  const [scopes, updateScopes] = useState({
    top_down: false,
    peer_to_peer: false,
    external_participants: false,
    bottom_up: false,
  });
  const [scopePeerSettings, updateScopePeerSettings] = useState({
    top_down: "inter",
    peer_to_peer: "inter",
    external_participants: "inter",
    bottom_up: "inter",
  });
  const [method, setMethod] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ConfigMainValidationSchema(isMultipleYear)),
  });
  const radios = [
    { name: "Single Year", value: "single" },
    { name: "Multiple Year", value: "multiple" },
  ];

  const resetForm = () => {
    const fields = ["years"];

    fields.forEach((fld) => setValue(fld, null));
  };
  const selectMethodOptions = [
    { label: "Objective", value: "Objective" },
    { label: "Balance Score Card", value: "Balance Score Card" },
  ];
  const selectIndustryOptions = [
    { label: "Information Technology", value: "Information Technology" },
    { label: "Auto Mobile", value: "Auto Mobile" },
    { label: "FMCG", value: "FMCG" },
  ];

  const saveHandler = async (data) => {
    data.year_selection = "single_year";
    data.company_id = 1;
    var requestData = formatRequest(data);
    try {
      const response = await axios.post(
        api_base_url + "/main_config",
        requestData,
      );
      console.log(response);
      // navigate("/admin-dashboard");
    } catch (error) {
      console.log(error);
    }
    // resetForm();
  };

  const toggleScopeEnable = (scope) => {
    const newScopes = { ...scopes };
    newScopes[scope] = !newScopes[scope];
    updateScopes(newScopes);
  };

  function formatRequest(data) {
    data.scope = {};
    if (scopes.bottom_up) {
      data.scope.bottom_up = {
        manager: data.bottom_up_manager && true,
        subordinates: data.bottom_up_subordinates && true,
        peer: data.bottom_up_peer && true,
        peer_type: scopePeerSettings.peer_to_peer,
        hierarchy: data.bottom_up_hierarchy && true,
      };
    }

    // Check for top-down data and add to scope
    if (scopes.top_down) {
      data.scope.top_down = {
        manager: data.top_down_manager && true,
        subordinates: data.top_down_subordinates && true,
        peer: data.top_down_peer && true,
        peer_type: scopePeerSettings.top_down,
        hierarchy: data.top_down_hierarchy && true,
      };
    }

    if (scopes.peer_to_peer) {
      data.scope.peer_to_peer = {
        manager: data.peer_to_peer_manager && true,
        subordinates: data.peer_to_peer_subordinates && true,
        peer: data.peer_to_peer_peer && true,
        peer_type: scopePeerSettings.peer_to_peer,
        hierarchy: data.peer_to_peer_hierarchy && true,
      };
    }
    if (scopes.external_participants) {
      data.scope.external_participants = {
        manager: data.external_participants_manager && true,
        subordinates: data.external_participants_subordinates && true,
        peer: data.external_participants_peer && true,
        peer_type: scopePeerSettings.external_participants,
        hierarchy: data.external_participants_hierarchy && true,
      };
    }
    return data;
  }

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="card">
          <div className="content container-fluid">
            <div className="card-body">
              <h3 className="border-bottom border-2 my-2">Main</h3>
              <div className="row mt-4">
                <div className="col-md-3 ">
                  <h4>Review Period</h4>
                </div>
                <div className="col-md-3 ">
                  <h4>From Date</h4>
                </div>
                <div className="col-md-3 ">
                  <h4>To Date</h4>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-md-3">
                  <ButtonGroup>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-primary"
                        name="primary_selection"
                        value={radio.value}
                        checked={isMultipleYear === radio.value}
                        onChange={(e) => setMultipleYear(e.currentTarget.value)}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
                <div className="col-md-3">
                  <div className="cal-icon">
                    <Controller
                      name="from_date"
                      control={control}
                      render={({ field }) => {
                        console.log(field);

                        return (
                          <DatePicker
                            {...field}
                            type="date"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="From Date"
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control datetimepicker"
                            ref={(elem) => {
                              elem && field.ref(elem.input);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <span className="text-danger">
                    {" "}
                    {errors.from_date?.message}{" "}
                  </span>
                </div>
                <div className="col-md-3">
                  <div className="cal-icon">
                    <Controller
                      name="to_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          type="date"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="To Date"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          className="form-control datetimepicker"
                          ref={(elem) => {
                            elem && field.ref(elem.input);
                          }}
                        />
                      )}
                    />
                  </div>
                  <span className="text-danger">
                    {" "}
                    {errors.to_date?.message}{" "}
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-3">
                  <h4>Method</h4>
                  <Controller
                    name="method"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        isClearable
                        options={selectMethodOptions}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          if (selectedOption) {
                            setMethod(selectedOption.value); // Update the state
                          } else {
                            setMethod(null); // Update the state
                          }
                        }}
                      />
                    )}
                  />
                  <span className="text-danger">
                    {" "}
                    {errors.method?.message}{" "}
                  </span>
                </div>

                <div className="col-md-3">
                  <h4>Industry</h4>
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        isClearable
                        options={selectIndustryOptions}
                      />
                    )}
                  />
                  <span className="text-danger">
                    {" "}
                    {errors.industry?.message}{" "}
                  </span>
                </div>
              </div>

              {method === "Objective" ? (
                <div className="row mt-2 ">
                  <div className="col-md-8">
                    <p className="fs-10 text-muted">
                      In "Objective" method each Employee will select their
                      goals in the form of KPI under the fixed KRAs which will
                      very from Employee to Employee. this method is simple and
                      requires input from each Employee for each GOAL
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {method === "Balance Score Card" ? (
                <div className="row mt-2">
                  <div className="col-md-8 text-muted">
                    <p>
                      Balance Score card method is used to setup the common
                      goals for entire company for a period of minimum 1 year or
                      more years to come, say 5 years. All the Employees work
                      toward a common goals of the company.witch result in
                      achieving success in a desireable period of time. in this
                      feature you will have to setup common goals of your
                      company under the four pillars of the activities
                      (Customer,Fiance,process & Learning) for all Employee in
                      the company right from the top CEO to Employee on the shop
                      floor.
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row mt-4">
                <div className="col-md-2 ">
                  <h4>Auto Populate KPIS</h4>
                </div>
                <div className="col-md-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      {...register("auto_kpi")}
                      type="checkbox"
                      id="flexCheckIndeterminate"
                    />
                  </div>
                </div>
              </div>

              <div className="border border-2 p-2 mt-4">
                <div className="row">
                  <div className="col-xl-12">
                    <h4 className="header-title mb-4">Scope</h4>
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          href="#topdown"
                          data-bs-toggle="tab"
                          aria-expanded="false"
                          className="nav-link"
                        >
                          Top Down
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#peertopeer"
                          data-bs-toggle="tab"
                          aria-expanded="true"
                          className="nav-link active"
                        >
                          Peer To Peer
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#externalparticipants"
                          data-bs-toggle="tab"
                          aria-expanded="false"
                          className="nav-link"
                        >
                          External Participants
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#bottomup"
                          data-bs-toggle="tab"
                          aria-expanded="false"
                          className="nav-link"
                        >
                          Bottom Up
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane" id="topdown">
                        <p className="text-muted">
                          In the top-down feedback process, managers evaluate
                          the performance of the employees on periodic basis and
                          provide them with feedback. The ratings are given by
                          the seniors to juniors.
                        </p>
                        <div className="row">
                          <div className="col-md-1">
                            <h5>Enable</h5>
                          </div>
                          <div className="col-md-1">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="top_down"
                                {...register("top_down_enable")}
                                onChange={() => toggleScopeEnable("top_down")}
                              />
                            </div>
                          </div>
                        </div>
                        {scopes.top_down && (
                          <div className="row mt-2">
                            <div className="col-md-12 ">
                              <h5>Goals Visibility</h5>
                              <div className="d-flex flex-column gap-2">
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        value="manager_default"
                                        {...register("top_down_manager")}
                                      />
                                      <label className="form-check-label">
                                        Manager: Default (Only Respective
                                        reporting managers will be able to view
                                        the goals and related data like
                                        comments, ratings, feedback etc.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        value="subordinates"
                                        {...register("top_down_subordinates")}
                                      />
                                      <label className="form-check-label">
                                        Subordinates (Subordinates will also be
                                        allowed to view the goals of their
                                        superiors)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        value="peer_to_peer"
                                        {...register("top_down_peer")}
                                      />
                                      <label className="form-check-label">
                                        Peers (Co-workers at similar levels will
                                        be allowed to view the goals within the
                                        same department)
                                      </label>
                                    </div>
                                    <div className="btn-group ms-5">
                                      <ButtonGroup>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="top_down_peer_type"
                                          value="inter"
                                          checked={
                                            scopePeerSettings.top_down ===
                                            "inter"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              top_down: "inter",
                                            });
                                          }}
                                        >
                                          Inter Department
                                        </ToggleButton>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="top_down_peer_type"
                                          value="intra"
                                          checked={
                                            scopePeerSettings.top_down ===
                                            "intra"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              top_down: "intra",
                                            });
                                          }}
                                        >
                                          Intra Department
                                        </ToggleButton>
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="hierarchy"
                                        {...register("top_down_hierarchy")}
                                      />
                                      <label className="form-check-label">
                                        Hierarchy (Goals of employees below will
                                        be viewed by the managers and their
                                        reporting heads.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="tab-pane show active" id="peertopeer">
                        <p className="text-muted">
                          Peer review helps a company to set up a balance and
                          give the management a deeper understanding about an
                          employee's coordination skills and assess his role as
                          a team player. A questionnaire is created with
                          objective answers and each set of response is averaged
                          to arrive at a rating.
                        </p>
                        <div className="row">
                          <div className="col-md-1">
                            <h5>Enable</h5>
                          </div>
                          <div className="col-md-1">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="peer_to_peer"
                                {...register("peer_to_peer")}
                                onChange={() =>
                                  toggleScopeEnable("peer_to_peer")
                                }
                              />
                            </div>
                          </div>

                          {scopes.peer_to_peer && (
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <h5>Goals Visibility</h5>
                                <div className="d-flex flex-column gap-2">
                                  <div classname="row">
                                    <div classname="col-md-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value="manager_default"
                                          {...register("peer_to_peer_manager")}
                                        />
                                        <label className="form-check-label">
                                          Manager: Default (Only Respective
                                          reporting managers will be able to
                                          view the goals and related data like
                                          comments, ratings, feedback etc.)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div classname="row">
                                    <div classname="col-md-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value="subordinates"
                                          {...register(
                                            "peer_to_peer_subordinates",
                                          )}
                                        />
                                        <label className="form-check-label">
                                          Subordinates (Subordinates will also
                                          be allowed to view the goals of their
                                          superiors)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div classname="row">
                                    <div classname="col-md-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value="peer_to_peer"
                                          {...register("peer_to_peer_peer")}
                                        />
                                        <label className="form-check-label">
                                          Peers (Co-workers at similar levels
                                          will be allowed to view the goals
                                          within the same department)
                                        </label>
                                      </div>
                                      <div className="btn-group ms-5">
                                        <ButtonGroup>
                                          <ToggleButton
                                            type="radio"
                                            variant="outline-primary"
                                            name="peer_to_peer_peer_type"
                                            value="inter"
                                            checked={
                                              scopePeerSettings.peer_to_peer ===
                                              "inter"
                                            }
                                            onClick={() => {
                                              updateScopePeerSettings({
                                                ...scopePeerSettings,
                                                peer_to_peer: "inter",
                                              });
                                            }}
                                          >
                                            Inter Department
                                          </ToggleButton>
                                          <ToggleButton
                                            type="radio"
                                            variant="outline-primary"
                                            name="peer_to_peer_peer_type"
                                            value="intra"
                                            checked={
                                              scopePeerSettings.peer_to_peer ===
                                              "intra"
                                            }
                                            onClick={() => {
                                              updateScopePeerSettings({
                                                ...scopePeerSettings,
                                                peer_to_peer: "intra",
                                              });
                                            }}
                                          >
                                            Intra Department
                                          </ToggleButton>
                                        </ButtonGroup>
                                      </div>
                                    </div>
                                  </div>
                                  <div classname="row">
                                    <div classname="col-md-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value="hierarchy"
                                          {...register(
                                            "peer_to_peer_hierarchy",
                                          )}
                                        />
                                        <label className="form-check-label">
                                          Hierarchy (Goals of employees below
                                          will be viewed by the managers and
                                          their reporting heads.)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="tab-pane" id="externalparticipants">
                        <p className="text-muted">
                          The system enables external agencies like customers,
                          vendors etc. rate the employees. Similar to Peer to
                          Peer, a series of questions are provided for
                          participant to quantify the feedback from external
                          participants.
                        </p>
                        <div className="row">
                          <div className="col-md-1">
                            <h5>Enable</h5>
                          </div>
                          <div className="col-md-1">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="external_participants"
                                {...register("external_participants")}
                                onChange={() =>
                                  toggleScopeEnable("external_participants")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {scopes.external_participants && (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <h5>Goals Visibility</h5>
                              <div className="d-flex flex-column gap-2">
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="manager_default"
                                        {...register(
                                          "external_participants_manager",
                                        )}
                                      />
                                      <label className="form-check-label">
                                        Manager: Default (Only Respective
                                        reporting managers will be able to view
                                        the goals and related data like
                                        comments, ratings, feedback etc.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="subordinates"
                                        {...register(
                                          "external_participants_subordinates",
                                        )}
                                      />
                                      <label className="form-check-label">
                                        Subordinates (Subordinates will also be
                                        allowed to view the goals of their
                                        superiors)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="external_participants"
                                        {...register(
                                          "external_participants_peer",
                                        )}
                                      />
                                      <label className="form-check-label">
                                        Peers (Co-workers at similar levels will
                                        be allowed to view the goals within the
                                        same department)
                                      </label>
                                    </div>
                                    <div className="btn-group ms-5">
                                      <ButtonGroup>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="external_participants_peer_type"
                                          value="inter"
                                          checked={
                                            scopePeerSettings.external_participants ===
                                            "inter"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              external_participants: "inter",
                                            });
                                          }}
                                        >
                                          Inter Department
                                        </ToggleButton>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="external_participants_peer_type"
                                          value="intra"
                                          checked={
                                            scopePeerSettings.external_participants ===
                                            "intra"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              external_participants: "intra",
                                            });
                                          }}
                                        >
                                          Intra Department
                                        </ToggleButton>
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="hierarchy"
                                        {...register(
                                          "external_participants_hierarchy",
                                        )}
                                      />
                                      <label className="form-check-label">
                                        Hierarchy (Goals of employees below will
                                        be viewed by the managers and their
                                        reporting heads.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="tab-pane" id="bottomup">
                        <p className="text-muted">
                          Bottom up evaluation helps the company to create
                          leaders. System helps to rate the leader by asking
                          series of questions and deriving a standard scorecard.
                          Here every subordinate answers objective type
                          questions about his manager. Average of the answers
                          provides a rating which will give fair idea about the
                          performance of the leader.
                        </p>
                        <div className="row">
                          <div className="col-md-1">
                            <h5>Enable</h5>
                          </div>
                          <div className="col-md-1">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="bottom_up"
                                {...register("bottom_up")}
                                onChange={() => toggleScopeEnable("bottom_up")}
                              />
                            </div>
                          </div>
                        </div>

                        {scopes.bottom_up && (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <h5>Goals Visibility</h5>
                              <div className="d-flex flex-column gap-2">
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="manager_default"
                                        {...register("bottom_up_manager")}
                                      />
                                      <label className="form-check-label">
                                        Manager: Default (Only Respective
                                        reporting managers will be able to view
                                        the goals and related data like
                                        comments, ratings, feedback etc.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="subordinates"
                                        {...register("bottom_up_subordinates")}
                                      />
                                      <label className="form-check-label">
                                        Subordinates (Subordinates will also be
                                        allowed to view the goals of their
                                        superiors)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="bottom_up"
                                        {...register("bottom_up_peer")}
                                      />
                                      <label className="form-check-label">
                                        Peers (Co-workers at similar levels will
                                        be allowed to view the goals within the
                                        same department)
                                      </label>
                                    </div>
                                    <div className="btn-group ms-5">
                                      <ButtonGroup>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="bottom_up_peer_type"
                                          value="inter"
                                          checked={
                                            scopePeerSettings.bottom_up ===
                                            "inter"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              bottom_up: "inter",
                                            });
                                          }}
                                        >
                                          Inter Department
                                        </ToggleButton>
                                        <ToggleButton
                                          type="radio"
                                          variant="outline-primary"
                                          name="bottom_up_peer_type"
                                          value="intra"
                                          checked={
                                            scopePeerSettings.bottom_up ===
                                            "intra"
                                          }
                                          onClick={() => {
                                            updateScopePeerSettings({
                                              ...scopePeerSettings,
                                              bottom_up: "intra",
                                            });
                                          }}
                                        >
                                          Intra Department
                                        </ToggleButton>
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                </div>
                                <div classname="row">
                                  <div classname="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="hierarchy"
                                        {...register("bottom_up_hierarchy")}
                                      />
                                      <label className="form-check-label">
                                        Hierarchy (Goals of employees below will
                                        be viewed by the managers and their
                                        reporting heads.)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12 d-flex flex-row-reverse">
                  <Button onClick={handleSubmit(saveHandler)} variant="primary">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ConfigMain;
