import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const NineScreen = () => {
    const [goals, setGoals] = useState([
        { id: 1, description: 'Mentor', target: '', remarks: '' },
        { id: 2, description: 'Mentee', target: '', remarks: '' },
    ]);

    const [selectedDepartment, setSelectedDepartment] = useState('IT');

    const [showModal, setShowModal] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [formData, setFormData] = useState({
        mentorName: '',
        designation: '',
        department: '',
    });

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPrograms([...programs, formData]);
        setFormData({
            mentorName: '',
            designation: '',
            dateTime: '',
            department: '',
            status: '',
        });
        handleClose();
    };




    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-md-1">

                                <label>Part</label>
                            </div>

                            <div className="col-md-2">
                                <Form.Group controlId="ratingType">
                                    <Form.Control as="select">
                                        <option value="2">3</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-1">
                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="ratingType">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control as="select">
                                        <option value="Numeric">Learning</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-1">
                                <Form.Label>.</Form.Label>

                                <Form.Group controlId="formPart">
                                    <Form.Check type="checkbox" />
                                </Form.Group>

                            </div>

                        </div>

                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-1">

                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Mandatory</Form.Label>

                                </Form.Group>
                            </div>
                            <div className="col-md-1">

                                <Form.Group controlId="formMandatory">
                                    <Form.Check
                                        type="switch"
                                        id="mandatory-switch"
                                        label=""
                                        name="mandatory"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        
                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-2">
                                <Form.Group controlId="ratingType">
                                    <Form.Label>Mentor Auto selection</Form.Label>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <div className="btn-group" role="group" aria-label="Department switcher">
                                    <button
                                        type="button"
                                        className={`btn btn-primary ${selectedDepartment === 'By Department' ? 'active' : ''}`}
                                        onClick={() => setSelectedDepartment('By Department')}
                                    >
                                        By Department
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-primary ${selectedDepartment === 'Manual' ? 'active' : ''}`}
                                        onClick={() => setSelectedDepartment('Manual')}
                                    >
                                        Manual
                                    </button>
                                   
                                </div>

                            </div>

                        </div>
                        
                       

                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-2">
                                <label>Mentor Name</label>
                            </div>
                            <div className="col-md-4">
                            <Form.Group controlId="ratingType">
                                <Form.Control as="select">
                                    <option value="2">2</option>
                                </Form.Control>
                                </Form.Group>
                                </div>
                            </div>



                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-7">
                                <h5>List of Mentor`s in company</h5>
                            </div>
                            <div className="col-2">
                                <Button variant="dark" onClick={handleShow}>
                                    + Add
                                </Button>
                            </div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr.no</th>
                                        <th>Mentor Name</th>
                                        <th>Designation</th>
                                        <th>Department</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {programs.map((program, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{program.mentorName}</td>
                                            <td>{program.designation}</td>
                                            <td>{program.department}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>



                        <div className="row" style={{ marginTop: 15 }}>
                            <div className="col">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Feedback</th>
                                            <th>Grading</th>
                                            <th>Meeting</th>
                                            <th>Objective</th>
                                            <th>Achievement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {goals.map(goal => (
                                            <tr key={goal.id}>
                                                <td>{goal.description}</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="mandatory-switch"
                                                        label=""
                                                        name="mandatory"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="mandatory-switch"
                                                        label=""
                                                        name="mandatory"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="mandatory-switch"
                                                        label=""
                                                        name="mandatory"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="mandatory-switch"
                                                        label=""
                                                        name="mandatory"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="mandatory-switch"
                                                        label=""
                                                        name="mandatory"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-2">
                                <label>Meeting With Mentor</label>
                            </div>
                            <div className="col-md-3">
                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Minimum</Form.Label>
                                    <input type="number" />

                                </Form.Group>

                            </div>
                            <div className="col-md-2">
                                <Form.Group controlId="formMandatory">
                                    <Form.Label>Maximum</Form.Label>
                                    <input type="number" />

                                </Form.Group>

                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-12">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                        </div>
                    </div>

                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Program</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formmentorName">
                                    <Form.Label>Mentor Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mentorName"
                                        value={formData.mentorName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formdesignation">
                                    <Form.Label>Designation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>


                               

                                <Form.Group controlId="formdepartment">
                                    <Form.Label>Department</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="department"
                                        value={formData.department}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                            
                                <Button variant="primary" type="submit" style={{ marginTop: 10 }}>
                                    Add Program
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default NineScreen;