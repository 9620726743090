import React from "react";
import { Link } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import { useEffect, useState } from 'react';
import axios from "axios";



const AllEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        var response = await axios.post("http://localhost:8000/api/get-employee-detail");
        var data = response.data.data
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedId(id);
  };


  if (isLoading) {
    return <div style={{ color: 'black' }}>Loading...</div>;
  }
  if (employeeData) {
    return (
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs
              maintitle="Employee"
              title="Dashboard"
              subtitle="Employee"
              modal="#add_employee"
              name="Add Employee"
              Linkname="/employees"
              Linkname1="/employees-list"
            />
            <EmployeeListFilter />

            <div className="row" >
              {employeeData.map((employee) => (
                <div
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                  key={employee.id}
                >
                  <div className="profile-widget">
                    <div className="dropdown profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        {/* <Link
                          className="dropdown-item"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_employee"
                        >
                          <i className="fa fa-pencil m-r-5" /> Edit
                        </Link> */}
                        <Link
                          className="dropdown-item"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={() => handleDeleteClick(employee._id)}
                        >
                          <i className="fa-regular fa-trash-can m-r-5" /> Delete
                        </Link>
                      </div>
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    </h4>
                    <div className="small text-muted"> {employee['first name']}</div>
                    <div className="small text-muted"> {employee['last name']} </div>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <AllEmployeeAddPopup />
        {/* Delete Modal */}
        <DeleteModal id={selectedId}  Name="Delete Employee" />
        {/* Delete Modal */}
      </div>
    );
  };
}

export default AllEmployee;
